import { useEffect } from 'react'
import { readScope } from '@lib/effector-helpers'

import { protectedRoute } from '@features/common'

export const initiateAdminPageModel = () => {
  protectedRoute()
  const isDone = true

  return {
    isDone,
  }
}
