import {
  createEvent,
  createStore,
  createEffect,
  combine,
  sample,
} from 'effector'
import { propEq, find } from 'ramda'
import { initLocalStorage } from '@lib/effector-helpers'
import { createFetching } from '@lib/fetching'
import { carcassApi } from '@api/carcass'

export const initiateCarcassModel = () => {
  // Events
  const resetCarcass = createEvent()
  const resetCarcassHeight = createEvent()
  const heightChanged = createEvent()
  const moduleWidthChanged = createEvent()
  const optionCarcassChanged = createEvent()
  const materialCarcassChanged = createEvent()
  const materialSideWallsChanged = createEvent()
  const materialSideWallsGroupChanged = createEvent()

  // Effects
  const loadCarcassData = createEffect({
    handler: ({ heightCurrent }) =>
      carcassApi.getCarcassData({ heightCurrent }),
  })

  loadCarcassData.watch(el => console.log('loadCarcassData', el))

  const loadCarcassDataFetching = createFetching(loadCarcassData)

  const loadHeightData = createEffect({
    handler: () => carcassApi.getHeightData(),
  })
  const loadHeightDataFetching = createFetching(loadHeightData)

  // Stores
  const $carcassData = createStore([])
  const $optionCarcassData = createStore([])

  const $heightList = createStore([])

  // Model
  $carcassData.on(loadCarcassData.done, (_, { result }) => result.items)

  $carcassData.watch(el => console.log('$carcassData', el))

  $optionCarcassData.on(
    loadCarcassData.done,
    (_, { result }) => result.optionCarcass,
  )

  $heightList.on(loadHeightData.done, (_, { result }) =>
    result.map(value => ({ value, label: value })),
  )

  const $heightCurrent = createStore(null)
    .on(heightChanged, (state, { value }) => value)
    .reset(resetCarcassHeight)

  initLocalStorage($heightCurrent)

  $heightCurrent.updates.watch(value => {
    console.log('$heightCurrent', value)
    resetCarcass()
    loadCarcassData({ heightCurrent: value })
  })

  // ***********************************************************

  const selectModuleWidth = createEvent()
  const selectOptionCarcass = createEvent()

  const $currentCarcass = createStore(null).reset(resetCarcass)
  const $currentModuleWidth = createStore(null).reset(resetCarcass)

  $currentCarcass.watch(el => console.log('currentCarcass', el))
  $currentModuleWidth.watch(el => console.log('currentModuleWidth', el))

  initLocalStorage($currentCarcass)
  initLocalStorage($currentModuleWidth)

  $currentCarcass.on(
    sample($carcassData, selectOptionCarcass, (data, { key }) =>
      find(propEq('name', key))(data),
    ),
    (_, result) => result,
  )

  const $currentFilling = $currentCarcass.map(currentCarcass => {
    if (currentCarcass) {
      const {
        filling: { header, main },
      } = currentCarcass

      return { header, main }
    }
    return { header: [], main: [] }
  })

  $currentModuleWidth.on(
    sample($currentCarcass, selectModuleWidth, (currentCarcass, { value }) => ({
      currentCarcass,
      value,
    })),
    (_, { currentCarcass, value }) =>
      find(propEq('moduleWidth', value))(currentCarcass.carcass),
  )

  const $moduleWidthList = $currentCarcass.map(
    value =>
      value &&
      value.carcass.map(el => ({
        value: el.moduleWidth,
        label: el.moduleWidth,
      })),
  )

  const $isDisabledMaterialCarcass = combine(
    $heightCurrent,
    $currentCarcass,
    $currentModuleWidth,
    (height, currentCarcass, currentModuleWidth) =>
      height && currentCarcass && currentModuleWidth,
  )

  // ***********************************************************

  const $optionCarcassCurrent = createStore(null)
    .on(optionCarcassChanged, (state, { value }) => value)
    .reset($currentCarcass.updates, $heightCurrent.updates, resetCarcass)

  initLocalStorage($optionCarcassCurrent)

  const $materialCarcassCurrent = createStore(null)
    .on(materialCarcassChanged, (state, { value }) => value)
    .reset($optionCarcassCurrent.updates, resetCarcass)

  initLocalStorage($materialCarcassCurrent)

  const $materialSideWallsCurrent = createStore(null)
    .on(materialSideWallsChanged, (state, { value }) => value)
    .reset($optionCarcassCurrent.updates, resetCarcass)

  initLocalStorage($materialSideWallsCurrent)

  const $materialSideWallsGroupCurrent = createStore(null)
    .on(materialSideWallsGroupChanged, (state, data) => data)
    .reset($optionCarcassCurrent.updates, resetCarcass)

  initLocalStorage($materialSideWallsGroupCurrent)

  materialSideWallsGroupChanged.watch(el => console.log('$materialSideWallsGroupCurrent', el))



  const $optionCarcassList = $optionCarcassData.map(optionCarcassData => {
    return optionCarcassData.map(({ carcassName }) => ({
      value: carcassName,
      label: carcassName,
    }))
  })

  const $materialCarcassList = combine(
    $optionCarcassData,
    $optionCarcassCurrent,
    (optionCarcassData, optionCarcassCurrent) => {
      const { materialCarcass = [] } = optionCarcassCurrent
        ? find(propEq('carcassName', optionCarcassCurrent))(
            optionCarcassData,
          ) || {}
        : []

      const materialCarcassList = materialCarcass.map(({ material }) => ({
        value: material,
        label: material,
      }))
      return materialCarcassList
    },
  )

  const $materialSideWallsList = combine(
    $optionCarcassData,
    $optionCarcassCurrent,
    (optionCarcassData, optionCarcassCurrent) => {
      const { materialSideWalls = [] } = optionCarcassCurrent
        ? find(propEq('carcassName', optionCarcassCurrent))(
            optionCarcassData,
          ) || {}
        : []

      const materialSideWallsList = materialSideWalls.map(({ material }) => ({
        value: material,
        label: material,
      }))
      return materialSideWallsList
    },
  )

  const $materialSideWallsListGroup = $optionCarcassData.map(
    optionCarcassData => {
      const materialSideWallsList = optionCarcassData.reduce((acc, val) => {
        const materialSideWalls = val.materialSideWalls.map(el => ({
          ...el,
          option: val.carcassName,
        }))

        return acc.concat(...materialSideWalls)
      }, [])

      const materialSideWallsListGroup = materialSideWallsList.map(
        ({ material, option }) => ({
          value: material,
          label: material,
          option,
        }),
      )
      console.log(materialSideWallsListGroup, 'result')
      return materialSideWallsListGroup
    },
  )

  //* **************************************priceCarcass

  const $priceCarcass = combine(
    $currentModuleWidth,
    $optionCarcassCurrent,
    (currentModuleWidth, optionCarcassCurrent) => {
      if (currentModuleWidth && optionCarcassCurrent) {
        return find(propEq('carcassName', optionCarcassCurrent))(
          currentModuleWidth.price,
        ).num
      }
      return 0
    },
  )

  //******************************************validation step

  const $isValidOptionCarcass = combine(
    $optionCarcassCurrent,
    $materialCarcassCurrent,
    $materialSideWallsCurrent,
    $optionCarcassList,
    (
      optionCarcassCurrent,
      materialCarcassCurrent,
      materialSideWallsCurrent,
      optionCarcassList,
    ) => {
      const valueList = optionCarcassList.map(item => item.value)
      if (valueList[0] === optionCarcassCurrent) {
        return (
          optionCarcassCurrent &&
          materialCarcassCurrent &&
          !materialSideWallsCurrent
        )
      }
      return (
        optionCarcassCurrent &&
        materialCarcassCurrent &&
        materialSideWallsCurrent
      )
    },
  )

  const $isValidCarcassStep = combine(
    $currentCarcass,
    $optionCarcassCurrent,
    $isValidOptionCarcass,
    (currentCarcass, optionCarcassCurrent, isValidOptionCarcass) => {
      return currentCarcass && optionCarcassCurrent && isValidOptionCarcass
    },
  )

  return {
    $heightList,
    $moduleWidthList,
    $optionCarcassList,
    $materialCarcassList,
    $materialSideWallsList,
    $materialSideWallsListGroup,
    $carcassData,

    heightChanged,
    moduleWidthChanged,
    optionCarcassChanged,
    materialCarcassChanged,
    materialSideWallsChanged,
    materialSideWallsGroupChanged,

    $heightCurrent,
    $optionCarcassCurrent,
    $materialCarcassCurrent,
    $materialSideWallsCurrent,
    $materialSideWallsGroupCurrent,

    $currentCarcass,
    $currentFilling,
    $currentModuleWidth,

    selectOptionCarcass,
    selectModuleWidth,

    $isDisabledMaterialCarcass,

    $priceCarcass,
    $isValidCarcassStep,
    resetCarcassHeight,

    loadHeightData,
    loadCarcassData,
    loadCarcassDataFetching,
    loadHeightDataFetching,
    resetCarcass,
  }
}
