import React from 'react'

import styled from 'styled-components'
import { Row, Col } from '@lib/styled-components-layout'
import { InputBase, TextareaBase, InputPhone } from '@features/common'

import { readScope } from '@lib/effector-helpers'

import { Text } from '@ui'

export const CheckoutForm = () => {
  const {
    firstNameField,
    lastNameField,
    phoneField,
    emailField,
    dealerNameField,
    addressField,
    discountField,
    commentField,
  } = readScope('checkout')

  return (
    <Col width="100%" as="form">
      <Text>Информация о заказчике</Text>
      <Row width="100%" gap="30px" justify="space-between">
        <InputBox>
          <InputBase
            type="text"
            label="Имя*"
            events={firstNameField.events}
            $stores={firstNameField.$stores}
          />
        </InputBox>
        <InputBox>
          <InputBase
            type="text"
            label="Фамилия*"
            events={lastNameField.events}
            $stores={lastNameField.$stores}
          />
        </InputBox>
      </Row>

      <Row width="100%" justify="space-between" gap="30px">
        <InputBox
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <InputPhone
            type="number"
            label="Телефон*"
            events={{ ...phoneField.events, setValue: phoneField.setValue }}
            $stores={phoneField.$stores}
          />
        </InputBox>
        <InputBox>
          <InputBase
            autoComplete="off"
            type="email"
            label="Email"
            events={emailField.events}
            $stores={emailField.$stores}
          />
        </InputBox>
      </Row>

      <Text style={{ padding: '30px 0 0' }}>Информация о дилере</Text>
      <Row width="100%" gap="30px" justify="space-between">
        <InputBox>
          <InputBase
            type="text"
            label="ФИО*"
            events={dealerNameField.events}
            $stores={dealerNameField.$stores}
          />
        </InputBox>
        <InputBox>
          <InputBase
            type="text"
            label="Адрес точки продажи*"
            events={addressField.events}
            $stores={addressField.$stores}
          />
        </InputBox>
      </Row>

      <Row width="100%" justify="space-between">
        <TextareaBox>
          <TextareaBase
            id=""
            name=""
            type="text"
            label="Комментарий"
            events={commentField.events}
            $stores={commentField.$stores}
            maxLength="300"
          />
        </TextareaBox>
      </Row>

      <Row justify="flex-start" align="center" padding="15px 0 45px">
        <Text style={{ marginRight: '10px' }}>СКИДКА</Text>
        <DiscountBox>
          <InputBase
            type="number"
            events={discountField.events}
            $stores={discountField.$stores}
          />
        </DiscountBox>

        <Text>%</Text>
      </Row>
    </Col>
  )
}

const InputBox = styled.div`
  max-width: 370px;
  width: 100%;
  height: 100px;
`
const DiscountBox = styled.div`
  max-width: 70px;
  width: 100%;
  margin-right: 10px;
`

const TextareaBox = styled.div`
  max-width: 100%;
  width: 100%;
`
