/* eslint-disable react/jsx-max-depth */
import React from 'react'
import { hot } from 'react-hot-loader'
import { useStore } from 'effector-react'

import { AccountLoader, CommonLoader, $isOpenModal } from '@features/common'

import { ToggleThemeProvider } from '@lib/theme-context'
import { lightTheme, darkTheme } from '@ui/theme'

import { Routes } from './routes'
import { GlobalStyles } from './global-styles'

export const App = hot(module)(() => {
  const isOpenModal = useStore($isOpenModal)

  return (
    <ToggleThemeProvider light={lightTheme} dark={darkTheme}>
      <>
        <GlobalStyles isOpenModal={isOpenModal} />
        <CommonLoader>
          <AccountLoader>
            <Routes />
          </AccountLoader>
        </CommonLoader>
      </>
    </ToggleThemeProvider>
  )
})
