import styled, { css } from 'styled-components'
import { Text } from '../text/text'

export const BtnLink = styled.button`
  padding: 0;
  border: none;
  border-bottom: 1px solid #72dcff;
  font-size: ${({ size }) => size || '14px'};
  line-height: 1;
  outline: 0;
  background-color: transparent;
  color: #72dcff;
  text-decoration: none;
  transition: all 0.25s ease;
  cursor: pointer;

  &:hover {
    border-color: transparent;
  }
`

export const ButtonDark = styled.button`
  height: 40px;
  width: 100%;
  border: 1px solid transparent;
  background-color: #343434;
  color: #FFFFFF;
  font-size: ${({ size }) => size || '14px'};
  line-height: 1;
  outline: none;
  cursor: pointer;
  padding: 0;
  border: 1px solid transparent;
  transition: all 0.25s ease;
  margin-top: ${p => (p.narrow ? 0 : 2)}rem;
  ${p =>
  p.disabled &&
  css`
     cursor: not-allowed;
   
    `};
  &:hover {
    border: 1px solid #343434;
    background-color: #FFFFFF;
    color: #343434;
    transition: all 0.25s ease;
`

export const ButtonDarkPDF = styled.button`
  height: 40px;
  max-width: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background-color: #343434;
  color: #FFFFFF;
  font-size: ${({ size }) => size || '14px'};
  line-height: 1;
  outline: none;
  cursor: pointer;
  padding: 0;
  border: 1px solid transparent;
  transition: all 0.25s ease;
  margin-top: 0;
  text-decoration: none;
  &:hover {
    border: 1px solid #343434;
    background-color: #FFFFFF;
    color: #343434;
    transition: all 0.25s ease;
`

export const Button = styled.button`
  height: 40px;
  width: 40px;
  border: 1px solid #A1A1A1;
  background-color: #FFFFFF;
  color: #3C3D3E;
  margin-top: 15px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
   &:hover {
    border: 1px solid transparent;
    background-color: #343434;
    color:  #FFFFFF;
    transition: all 0.25s ease;
    ${Text} {
      color: #FFFFFF;
    }
    
`

export const ButtonShowModal = styled.button`
  height: 40px;
  width: 100%;
  position: relative;
  border: 1px solid #a1a1a1;
  background-color: #ffffff;
  color: #3c3d3e;
  cursor: pointer;
  outline: none;
  margin-bottom: 15px;
  text-align: left;
  padding-left: 15px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #ffffff;
  // background-color: ${p => (p.disabled ? '#f2f2f2' : '#ffffff')};
  ${p =>
  p.disabled &&
  css`
      background-color: #f2f2f2;
      color: var(--color-fontLight);
    `};
`
