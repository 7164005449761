import { createStore, createEffect, createEvent } from 'effector'
import html2canvas from 'html2canvas'

import { useClearOnUnmount, initLocalStorage } from '@lib/effector-helpers'
import { createFetching } from '@lib/fetching'

export const initiateOrderPrintModel = () => {
  // Events
  const setDataString = createEffect({
    handler: array =>
      Promise.all(
        // eslint-disable-next-line unicorn/prefer-query-selector
        array.map(({ elemId }) => {
          window.scrollTo(0, 0)
          return html2canvas(document.getElementById(elemId), { scale: 2 })
        }),
      ),
  })
  const resetDataStringLoading = createEvent()

  const setDataStringLoading = createFetching(setDataString, null, {
    reset: resetDataStringLoading,
  })
  const resetDataString = createEvent()

  // Store
  const $dataStringOrderPrint = createStore({})

  // Model
  $dataStringOrderPrint
    .on(setDataString.done, (state, { params, result }) => {
      const newData = result.reduce(
        (acc, el, index) => ({
          ...acc,
          [params[index].name]: el.toDataURL('image/png'),
        }),
        {},
      )

      return { ...state, ...newData }
    })
    .reset(resetDataString)

  initLocalStorage($dataStringOrderPrint)

  return useClearOnUnmount({
    $dataStringOrderPrint,
    setDataString,
    setDataStringLoading,
    resetDataString,
    resetDataStringLoading,
  })
}
