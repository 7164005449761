// @flow
import * as React from 'react'
import { useStore } from 'effector-react'
import { type CurrentAccount } from '@api/account'
import { $session } from '../model/session.store'

type Props = {|
  render?: ({
    account: ?CurrentAccount,
    accountId: ?number,
    role: ?string,
  }) => React.Node,
  renderExists?: ({
    account: Session,
    accountId: number,
    role: string,
  }) => React.Node,
  renderEmpty?: ({ account: null, accountId: null, role: null }) => React.Node,
|}

export const WithAccount = (props: Props) => {
  const session = useStore($session)

  if (session && props.renderExists) {
    return props.renderExists({
      account: session,
      accountId: session.id,
      role: session.role,
    })
  }

  if (!session && props.renderEmpty) {
    return props.renderEmpty({ account: null, accountId: null, role: null })
  }

  return props.render
    ? props.render({
        account: session,
        accountId: session ? session.id : null,
        role: session ? session.role : null,
      })
    : null
}
