import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Row, Col } from '@lib/styled-components-layout'

export const InputCashier = ({
  autoComplete,
  readonly,
  disabled,
  error,
  label,
  decorRight,
  decorLeft,
  tooltip,
  positionTooltip,
  description,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  type,
  value,
  width,
  inputInner,
}) => (
  <Col width="100%" padding="" style={{ position: 'relative' }}>
    <InputLabel>{label}</InputLabel>
    <Row align="center">
      <Col
        width="100%"
        // width={width || '240px'}
        // style={{ position: 'relative', minWidth: width || '370px' }}
      >
        {inputInner || (
          <InputNative
            autoComplete={autoComplete}
            disabled={disabled}
            error={Boolean(error)}
            name={name}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
            decorLeft={decorLeft}
            decorRight={decorRight}
            {...(readonly && { readOnly: true })}
          />
        )}

        {tooltip && (
          <InputTooltip position={positionTooltip}>{tooltip}</InputTooltip>
        )}
        {error && (
          <InputTooltip isError position="left">
            {error}
          </InputTooltip>
        )}
        {decorRight && <InputDecor>{decorRight}</InputDecor>}
        {decorLeft && <InputDecor left>{decorLeft}</InputDecor>}
      </Col>
      {/*<InputDescription>{description}</InputDescription>*/}
    </Row>
  </Col>
)

InputCashier.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.string,
  positionTooltip: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  decorRight: PropTypes.string,
  decorLeft: PropTypes.string,
  readonly: PropTypes.bool,
  width: PropTypes.string,
  inputInner: PropTypes.node,
}

InputCashier.defaultProps = {
  autoComplete: 'undefined',
  disabled: false,
  error: undefined,
  label: undefined,
  tooltip: undefined,
  description: undefined,
  positionTooltip: 'left',
  name: undefined,
  onBlur: undefined,
  onFocus: undefined,
  onChange: undefined,
  placeholder: undefined,
  type: 'text',
  value: '',
  decorRight: undefined,
  decorLeft: undefined,
  readonly: undefined,
  width: '280px',
  inputInner: undefined,
}

export const inputNativeMixin = p => css`
  border: none;
  border-radius: 4px;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: ${() => (p.decorLeft ? '3.5rem' : '2rem')};
  padding-right: ${() => (p.decorRight ? '3.5rem' : '2rem')};
  font-size: 1.4rem;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  color: var(--color-fontPrimary);
  outline: none;
  box-shadow: ${() =>
    p.error ? '0 0 0 1px var(--color-warningLight)' : 'none'};
  border-color: ${() => (p.error ? 'var(--color-warningLight)' : 'none')};
  transition: box-shadow 120ms, border-color 120ms;

  &:focus {
    box-shadow: 0 0 0 1px grey;
    border-color: grey;
  }

  &:disabled {
    background-color: rgba(80, 80, 80, 0.1);
  }

  ::-webkit-input-placeholder {
    color: #636363;
  }
`

export const InputNative = styled.input`
  ${inputNativeMixin};
`

const InputDecor = styled.div`
  position: absolute;
  top: 50%;
  left: 225px;
  color: #000;
  font-size: 25px;
  transform: translateY(-50%);

  ${p =>
    p.left
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
`

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #3c3d3e;
  text-align: left;
`

export const InputDescription = styled.div`
  color: #444;
  font-size: 14px;
  margin-left: 15px;
  text-align: left;
`

export const InputTooltip = styled.div`
  padding: 10px;
  font-size: 14px;
  border-radius: 2px;
  position: absolute;
  max-width: 220px;
  ${p =>
    p.isError
      ? css`
          background-image: linear-gradient(
            0,
            #4e0000 0,
            #600 6%,
            #830000 97%,
            #962323 100%
          );
        `
      : css`
          background-image: linear-gradient(0, #1a7160, #1f8c77);
        `};
  color: var(--color-fontPrimary);

  ${p => positionTooltip(p.position, p.isError ? '#770002' : '#1f8c77')}

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
`

const positionTooltip = (position, color) => {
  switch (position) {
    case 'left':
      return css`
        top: 50%;
        left: 0;
        transform: translateX(-100%) translateX(-10px) translateY(-50%);

        &:after {
          top: 50%;
          left: 100%;
          border-left: 8px solid ${color};
          transform: translateY(-50%);
        }
      `
    case 'right':
      return css`
        top: 50%;
        left: 0;
        transform: translateX(100%) translateX(10px) translateY(-50%);

        &:after {
          top: 50%;
          left: -8px;
          border-right: 8px solid ${color};
          transform: translateY(-50%) translateY(4px);
        }
      `
    case 'top':
      return css`
        left: 0;
        top: 0;
        transform: translateY(-100%) translateY(-10px);

        &:after {
          top: 0;
          left: 50%;
          border-right: 8px solid ${color};
          transform: translateY(50%) translateY(4px) rotate(-90deg);
        }
      `
    default:
      return null
  }
}
