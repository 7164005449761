import React from 'react'
import { HeaderEmpty, unProtectedRoute } from '@features/common'
import { Col } from '@lib/styled-components-layout'

import { LoginForm } from '@features/join'

import { CenterContentTemplate, Container } from '@ui/templates'

export const LoginPage = () => {
  unProtectedRoute()
  return (
    <CenterContentTemplate isFull header={<HeaderEmpty />}>
      <Container justify="center" align="center">
        <Col align="stretch" width="30rem">
          <LoginForm />
        </Col>
      </Container>
    </CenterContentTemplate>
  )
}
