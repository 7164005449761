import { useStore } from 'effector-react'
import { useLocation } from '@lib/router-hooks'
import { history } from '@lib/routing'

import { $session } from '../model/session.store'
import { loadSessionFetching } from '../model/session.events'

export const protectedRoute = () => {
  const { pathname } = useLocation()
  const status = useStore(loadSessionFetching.status)
  const session = useStore($session)

  if (status !== 'initial' && status !== 'loading' && !session) {
    history.push({
      pathname: '/login',
      state: { from: pathname },
    })
  }
}

export const unProtectedRoute = () => {
  const { pathname } = useLocation()
  const status = useStore(loadSessionFetching.status)
  const session = useStore($session)

  if (status !== 'initial' && status !== 'loading' && session) {
    history.push({
      pathname: '/',
      state: { from: pathname },
    })
  }
}
