import React from 'react'

const common = {
  light: {
    viewBox: '0 0 17.274 18',
    path: () => (
      <path d="M10.689 15.73v.526a.834.834 0 01-.708.826l-.13.477a.595.595 0 01-.573.441H7.993a.595.595 0 01-.574-.44l-.126-.478a.838.838 0 01-.712-.83v-.526c0-.282.226-.507.508-.507h3.092c.282.003.508.23.508.51zm2.381-7.09a4.412 4.412 0 01-1.244 3.078 4.066 4.066 0 00-1.089 2.208.733.733 0 01-.726.618H7.26c-.359 0-.67-.259-.722-.614a4.111 4.111 0 00-1.096-2.215 4.415 4.415 0 01-1.237-3.026 4.421 4.421 0 014.403-4.482 4.43 4.43 0 014.463 4.434zM9.137 5.957a.5.5 0 00-.5-.5 3.203 3.203 0 00-3.2 3.2.5.5 0 001 0c0-1.215.989-2.2 2.2-2.2.278 0 .5-.223.5-.5zm-.5-3.204a.5.5 0 00.5-.5V.5a.5.5 0 00-1 0v1.752a.5.5 0 00.5.5zM2.752 8.637a.5.5 0 00-.5-.5H.5a.5.5 0 000 1h1.752c.278 0 .5-.222.5-.5zm14.022-.5h-1.752a.5.5 0 000 1h1.752a.5.5 0 000-1zM3.77 12.8l-1.24 1.24a.498.498 0 10.703.708l1.241-1.24a.498.498 0 10-.704-.708zm9.382-8.178a.5.5 0 00.352-.144l1.24-1.241a.498.498 0 000-.707.498.498 0 00-.707 0l-1.24 1.24a.498.498 0 00.355.852zM3.77 4.474a.5.5 0 00.704-.707l-1.24-1.241a.498.498 0 00-.708 0 .499.499 0 000 .707zm9.734 8.326a.498.498 0 00-.708 0 .498.498 0 000 .707l1.241 1.241a.5.5 0 00.704-.707z" />
    ),
  },
  forward: {
    viewBox: '0 0 100 87.379',
    path: () => (
      <path d="M99.388 42.152L57.702.603a2.106 2.106 0 0 0-2.27-.45 2.083 2.083 0 0 0-1.284 1.925v22.909H2.083C.933 24.987 0 25.92 0 27.07v33.322c0 1.15.933 2.083 2.083 2.083h52.066v22.82a2.087 2.087 0 0 0 2.082 2.084c.542 0 1.075-.213 1.475-.609l41.686-41.669a2.086 2.086 0 0 0-.004-2.949z" />
    ),
  },
  alert: {
    viewBox: '0 0 31.003 27.252',
    path: () => (
      <path d="M29.501 27.252h-28a1.5 1.5 0 0 1-1.3-2.25l14-24.25a1.5 1.5 0 0 1 2.6 0l14 24.249a1.5 1.5 0 0 1-1.3 2.25zm-25.4-3h22.8l-11.4-19.75zm12.9-7.5v-5a1.5 1.5 0 0 0-3 0v5a1.5 1.5 0 0 0 3 0zm-1.5 2.5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5z" />
    ),
  },
  profile: {
    viewBox: '0 0 32 32',
    path: () => (
      <path d="M28.387 21.714l-5.446-2.475a4.07 4.07 0 0 1-1.641-1.365 1.968 1.968 0 0 1-.085-2.132A13.468 13.468 0 0 0 23 9c0-4.479-2.164-9-7-9S9 4.521 9 9a13.468 13.468 0 0 0 1.788 6.742 1.968 1.968 0 0 1-.085 2.132 4.07 4.07 0 0 1-1.644 1.365l-5.446 2.475A6.175 6.175 0 0 0 0 27.325V29c0 2.479 8.7 3 16 3 7.3 0 16-.521 16-3v-1.675a6.175 6.175 0 0 0-3.613-5.611z" />
    ),
  },
  close: {
    viewBox: '0 0 100 100',
    path: () => (
      <path d="M62.369 50l36.223-36.223a4.733 4.733 0 0 0 0-6.737L92.96 1.408a4.733 4.733 0 0 0-6.737 0L50 37.631 13.777 1.408a4.733 4.733 0 0 0-6.737 0L1.408 7.04a4.733 4.733 0 0 0 0 6.737L37.631 50 1.408 86.223a4.733 4.733 0 0 0 0 6.737l5.632 5.632a4.733 4.733 0 0 0 6.737 0L50 62.369l36.223 36.223a4.733 4.733 0 0 0 6.737 0l5.632-5.632a4.733 4.733 0 0 0 0-6.737z" />
    ),
  },
  circleClose: {
    viewBox: '0 0 100 100',
    path: () => (
      <path d="M85.373 14.628c-19.504-19.503-51.24-19.505-70.745 0-19.505 19.505-19.503 51.24 0 70.745 19.503 19.505 51.24 19.505 70.745 0 19.503-19.506 19.503-51.242 0-70.745zM69.727 69.727a3.847 3.847 0 0 1-5.441 0L50 55.44 35.036 70.406a3.847 3.847 0 1 1-5.441-5.441L44.559 50 30.274 35.715a3.847 3.847 0 1 1 5.44-5.441L50 44.559l13.605-13.604a3.847 3.847 0 1 1 5.44 5.441L55.442 50l14.286 14.286a3.847 3.847 0 0 1 0 5.44z" />
    ),
  },
  resize: {
    viewBox: '0 0 100 100',
    path: () => (
      <path d="M14.286 64.286H0V100h35.714V85.714H14.286zM0 35.714h14.286V14.286h21.428V0H0zm85.714 50H64.286V100H100V64.286H85.714zM64.286 0v14.286h21.428v21.428H100V0z" />
    ),
  },
  user: {
    viewBox: '0 0 100 100',
    path: () => (
      <path d="M95.2 76.8c0-13.2-10.4-23.7-23.6-23.8-14.2-.1-28.4-.1-42.7 0-13.3.1-23.5 10.4-23.7 23.7-.1 4.7 0 9.4 0 14 0 4.8 2.5 7.2 7.3 7.3h75.4c4.7 0 7.3-2.6 7.3-7.3V76.8zM50.1 50.8c13 0 23.6-10.5 23.6-23.4 0-13.1-10.5-23.7-23.5-23.8-12.9 0-23.6 10.8-23.6 23.7 0 12.9 10.6 23.5 23.5 23.5z" />
    ),
  },
  check: {
    viewBox: '0 0 100 100',
    path: () => (
      <path d="M88.095 0h-76.19C5.357 0 0 5.357 0 11.905v76.19C0 94.643 5.357 100 11.905 100h76.19C94.643 100 100 94.643 100 88.095v-76.19C100 5.357 94.643 0 88.095 0zm7.143 88.095a7.103 7.103 0 0 1-7.143 7.143h-76.19a7.103 7.103 0 0 1-7.143-7.143v-76.19a7.103 7.103 0 0 1 7.143-7.143h76.19a7.103 7.103 0 0 1 7.143 7.143z" />
    ),
  },
  checkFilled: {
    viewBox: '0 0 100 100',
    path: () => [
      <path d="M70.675 31.613L42.188 60.1 29.325 47.238a3.906 3.906 0 1 0-5.525 5.524l15.625 15.625a3.894 3.894 0 0 0 2.763 1.144c1 0 1.999-.381 2.762-1.144l31.25-31.25a3.906 3.906 0 1 0-5.525-5.524z" />,
      <path d="M88.281 0H11.72C5.257 0 0 5.257 0 11.719V88.28C0 94.743 5.257 100 11.719 100H88.28C94.743 100 100 94.743 100 88.281V11.72C100 5.257 94.743 0 88.281 0zm3.906 88.281a3.91 3.91 0 0 1-3.906 3.906H11.72a3.91 3.91 0 0 1-3.906-3.906V11.72a3.91 3.91 0 0 1 3.906-3.906H88.28a3.91 3.91 0 0 1 3.906 3.906z" />,
    ],
  },
  refresh: {
    viewBox: '0 0 100 100',
    path: () => (
      <path d="M20.5 3.5C18.3 1.4 15.3 0 12 0 5.4 0 0 5.4 0 12s5.4 12 12 12c5.6 0 10.3-3.8 11.6-9h-3.1c-1.2 3.5-4.6 6-8.5 6-5 0-9-4-9-9s4-9 9-9c2.5 0 4.7 1 6.3 2.7l-4.8 4.8H24V0l-3.5 3.5z" />
    ),
  },
  star: {
    viewBox: '0 0 99 94.1',
    path: () => (
      <path d="M18.9 94.1l30.5-17.9 30.7 17.9-6.4-33.7L99 35.9l-33.6-4.2L49.4 0 33.6 31.7 0 35.9l25.3 24.5z" />
    ),
  },
  star2: {
    viewBox: '0 0 100 95.354',
    path: () => (
      <path d="M97.649 34.466l-32.56-2.793L52.37 1.567c-.883-2.09-3.845-2.09-4.728 0L34.916 31.673 2.351 34.466C.093 34.66-.826 37.479.888 38.962l24.701 21.4L18.187 92.2c-.513 2.212 1.884 3.952 3.824 2.777l27.986-16.878 27.987 16.878c1.945 1.17 4.337-.57 3.824-2.777l-7.402-31.837 24.7-21.4c1.72-1.483.807-4.301-1.457-4.496zM65.346 54.634a5.13 5.13 0 0 0-1.638 5.046l3.978 17.098-15.035-9.065a5.124 5.124 0 0 0-5.302 0l-15.035 9.065 3.978-17.098a5.144 5.144 0 0 0-1.638-5.046L21.38 43.136l17.499-1.505a5.146 5.146 0 0 0 4.291-3.115l6.833-16.17 6.832 16.17a5.12 5.12 0 0 0 4.291 3.115l17.5 1.505z" />
    ),
  },
  plugin: {
    viewBox: '0 0 100 100',
    path: () => (
      <path d="M88.1 47.6H81v-19c0-5.2-4.3-9.5-9.5-9.5h-19V12c-.1-6.7-5.5-12-12-12S28.6 5.3 28.6 11.9V19h-19C4.3 19 0 23.3 0 28.6v18.1h7.1c7.1 0 12.9 5.8 12.9 12.9S14.2 72.4 7.1 72.4H0v18.1c0 5.2 4.3 9.5 9.5 9.5h18.1v-7.1c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v7.1h18.1c5.2 0 9.5-4.3 9.5-9.5v-19h7.1c6.6 0 11.9-5.3 11.9-11.9s-5.3-12-11.9-12z" />
    ),
  },
}

const socialIcons = {
  twitter: {
    viewBox: '0 0 100 82',
    path: () => (
      <path d="M100 9.61a40.966 40.966 0 0 1-11.78 3.231A20.6 20.6 0 0 0 97.239 1.5a41.356 41.356 0 0 1-13.035 4.98A20.456 20.456 0 0 0 69.23 0C57.9 0 48.716 9.186 48.716 20.509c0 1.606.18 3.174.53 4.674C32.2 24.327 17.085 16.16 6.968 3.749a20.358 20.358 0 0 0-2.774 10.31 20.504 20.504 0 0 0 9.123 17.073 20.516 20.516 0 0 1-9.292-2.575v.256c0 9.936 7.074 18.228 16.453 20.115-1.718.463-3.53.719-5.405.719a19.69 19.69 0 0 1-3.862-.381c2.612 8.155 10.186 14.085 19.16 14.247-7.018 5.5-15.867 8.767-25.477 8.767-1.656 0-3.287-.1-4.893-.28 9.08 5.83 19.859 9.229 31.444 9.229 37.737 0 58.364-31.257 58.364-58.364l-.068-2.656A40.965 40.965 0 0 0 100 9.61z" />
    ),
  },
  facebook: {
    viewBox: '0 0 100 193',
    path: () => (
      <path d="M64.914 192.578v-87.837h29.471l4.422-34.242H64.914V48.64c0-9.91 2.74-16.665 16.969-16.665L100 31.968V1.34C96.867.933 86.112 0 73.595 0 47.455 0 29.56 15.955 29.56 45.249v25.25H0v34.242h29.56v87.837z" />
    ),
  },
  google: {
    viewBox: '0 0 100 77',
    path: () => (
      <path d="M76.744 34.108H40.93v11.938h21.85c-3.26 10.379-12.953 17.907-24.408 17.907-14.129 0-25.581-11.453-25.581-25.581 0-14.129 11.453-25.582 25.58-25.582a25.5 25.5 0 0 1 18.187 7.593l8.666-9.419C58.304 4.183 48.827 0 38.372 0 17.18 0 0 17.18 0 38.372s17.18 38.372 38.372 38.372c18.564 0 34.817-13.184 38.372-30.698V34.108zM86.395 26.762v7.326H79.07v6.279h7.325v7.325h6.28v-7.325H100v-6.28h-7.325v-7.325z" />
    ),
  },
}

export const icons = {
  ...common,
  ...socialIcons,
}
