import React from 'react'
import { renderRoutes } from 'react-router-config'

import { CommonContentTemplate } from '@features/common'
import { OrderInfo } from '@features/order'

import { Row, Col } from '@lib/styled-components-layout'

import { initiatePageModel } from './model'
import styled, { css } from 'styled-components'
import { StepLine } from '@ui'


// eslint-disable-next-line react/prop-types
export const HomePage = ({ route }) => {
  const { activeIndex, stepsContent } = initiatePageModel()

  return (
    <CommonContentTemplate>
      <HomeWrap gap="30px">
        <ColInfo>
          <Row width="100%" padding="15px" justify="center">
            <StepLine activeIndex={activeIndex} list={stepsContent} />
          </Row>
          <InfoWrap>
            <Col width="100%">{renderRoutes(route.routes)}</Col>
          </InfoWrap>
        </ColInfo>
        <ColOrder>
          <OrderInfo />
        </ColOrder>
      </HomeWrap>

      <InfoPdf />
    </CommonContentTemplate>
  )
}

const InfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 35px 40px;
  background-color: white;
  min-height: 748px;
  height: 100%;
  box-shadow: 0px 4px 30px rgba(169, 206, 227, 0.25);
`

const HomeWrap = styled.div`
  display: flex;
  flex-direction: row;
  ${p => p.gap &&
  css`
      & > :not(:first-child) {
        margin-left: ${p.gap};
      }
    `}
  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    & > :not(:first-child) {
        margin-left: 0;
     }
    ${p => p.gap &&
    css`
      & > :not(:first-child) {
        margin-top: ${p.gap};
      }
    `}
  }
`

const ColInfo = styled.div`
  width: 67%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1300px) {
    width: 80%;
  };
  @media (max-width: 1150px) {
    width: 100%;
  }
`

const ColOrder = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 4px 30px rgba(169, 206, 227, 0.25);
  overflow: hidden;
  @media (max-width: 1300px) {
    width: 46%;
  };
   @media (max-width: 930px) {
    width: 100%;
  }
`


//pdf***********************************************************************

import { OrderComment, OrderDescription } from '@features/order/molecules'
import { useStore } from 'effector-react'
import { readScope } from '@lib/effector-helpers'

import { H2, Text, FillingImg, Header } from '@ui'
import { BorderBox } from '@features/order/molecules'
import { ItemBox, ItemMain, ItemBtn } from '@ui/molecules/filling-img'
import { ItemHeader } from '@ui/molecules/header'

const InfoPdf = () => {

  const { $fillingMain, $fillingHeader, } = readScope('filling')
  const { $doorMain } = readScope('door')

  const fillingMainList = useStore($fillingMain)
  const fillingHeaderList = useStore($fillingHeader)

  const doorMainList = useStore($doorMain)

  const dateNumber = new Date()
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  const dateOrder = dateNumber.toLocaleString('ru', options)

  return (
    <BoxPdf id="canvasImgComment">
      <img src={'/images/logoPdf.png'} alt='logo'/>
      <BoxDate>{dateOrder}</BoxDate>
      <BoxInfo>
        <Box>
          <OrderDescription />
        </Box>
        <Box>
          <OrderComment />
        </Box>
      </BoxInfo>
      <H2>Вариант наполнения и освещения</H2>
      <BoxInfoImg>
        <Col>
          <FillingImg
            listMain={fillingMainList}
            header={<Header listHeader={fillingHeaderList} /> }
          />
        </Col>
        <Col justify='flex-end'>
          <FillingImg
            listMain={doorMainList}
          />
        </Col>
      </BoxInfoImg>
    </BoxPdf>
  )
}

export const BoxPdf = styled.div`
  position: absolute;
  top:0;
  left: -9999px;
  height: 840px;
  width: 595px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  ${H2} {
    text-align: center;
  }
`
export const BoxDate = styled.div`
  margin: 10px 0 20px 0;
`
export const Box = styled.div`
  width: 49%;
  ${Text} {
    font-size: 13px;
  };
  ${Col} {
    padding: 0 10px 10px 0;
      ${BorderBox} {
      padding: 5px 0;
    }
  }
`
export const BoxInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`
export const BoxInfoImg = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  ${Col} {
    padding: 0;
    height: 100%;
    ${ItemBox} {
      margin-bottom: 5px;
      ${ItemHeader} {
        width: 34px!important;
      };
      ${ItemMain} {
        width: 34px!important;
        height: 150px;
      };
      ${ItemBtn} {
        width: 34px!important;
        height: 34px;
      }
    }
  }
`



















