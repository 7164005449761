import React from 'react'
import PropTypes from 'prop-types'

import { useStore } from 'effector-react'
import { Textarea } from '@ui'

export const TextareaBase = ({ type, label, events, $stores, disabled, maxLength }) => {
  const { value, isChanged, error } = useStore($stores)

  return (
    <Textarea
      {...events}
      type={type}
      label={label}
      value={value}
      disabled={disabled}
      maxLength={maxLength}
      error={isChanged && error} // also can be change
    />
  )
}

TextareaBase.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  events: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  $stores: PropTypes.object.isRequired,
  label: PropTypes.string,
}

TextareaBase.defaultProps = {
  label: null,
}
