import React from 'react'
import { useStore } from 'effector-react'

import { Row, Col } from '@lib/styled-components-layout'

import { readScope } from '@lib/effector-helpers'
import styled from 'styled-components'
import { history } from '@lib/routing'

import { showModal } from '@features/common'
import { DoorModal } from '../molecules'
import {
  Text,
  Button,
  ButtonDark,
  StepTitle,
  SelectCashier,
  FillingImg,
} from '@ui'

const showModalDoor = index => showModal({ comp: DoorModal, props: { index } })

export const Door = () => {
  const {
    $currentModuleWidth,
    $optionCarcassCurrent,
    $materialSideWallsListGroup,
    $materialSideWallsGroupCurrent,
    materialSideWallsGroupChanged,
  } = readScope('carcass')

  const {
    frameDoorsChanged,
    $frameDoorsList,
    $frameDoorCurrent,
    $frameDoorsHandleTypeList,
    $frameDoorsHandleTypeCurrent,
    frameDoorsHandleTypeChanged,
    $doorMain,
    $doorCode,
    $isValidDoorStep,
    $isDR,
    $isDP,
  } = readScope('door')

  const frameDoorsList = useStore($frameDoorsList)
  const frameDoor = useStore($frameDoorCurrent)

  const frameDoorsHandleTypeList = useStore($frameDoorsHandleTypeList)
  const frameDoorHandleType = useStore($frameDoorsHandleTypeCurrent)

  const materialSideWallsListGroup = useStore($materialSideWallsListGroup)

  const { value: materialSideWallsGroupCurrent = '' } = useStore($materialSideWallsGroupCurrent) || {}

  const optionCarcassCurrent = useStore($optionCarcassCurrent)
  const { moduleWidth = '' } = useStore($currentModuleWidth) || {}

  const doorMainList = useStore($doorMain)
  const doorCode = useStore($doorCode)
  const { DP = '', DR = '' } = doorCode

  const isValidStep = useStore($isValidDoorStep)
  const isDR = useStore($isDR)
  const isDP = useStore($isDP)

  return (
    <Col width="100%" height="100%">
      <StepTitle title="Шаг 3." titleStep="Выбор дверей и типа ручек" />

      <Row
        justify="space-between"
        padding="0 0 15px"
        gap="25px"
        style={{ borderBottom: '1px solid #A1A1A1' }}
      >
        <Col basis="50%" gap="10px">
          {optionCarcassCurrent !== 'ЛДСП' ? (
            <Col>
              <Text style={{ marginBottom: '10px' }}>
                Материал плитных дверей
              </Text>
              <MaterialBox>{isDP ? optionCarcassCurrent : ''}</MaterialBox>
            </Col>
          ) : (
            <SelectCashier
              options={materialSideWallsListGroup}
              placeholder="Выбрать..."
              label="Материал плитных дверей"
              value={isDP ? materialSideWallsGroupCurrent : ''}
              onChange={materialSideWallsGroupChanged}
              isDisabled={!isDP}
            />
          )}

          <Row gap="5px">
            <Text>Код комплекта дверей:</Text>
            <Text
              bold
            >{`702.${moduleWidth}-DP${DP} DR${DR}-${frameDoorHandleType}`}</Text>
          </Row>
        </Col>

        <Col basis="50%">
          <SelectCashier
            options={frameDoorsList}
            placeholder="Выбрать..."
            label="Материал рамочных дверей"
            value={frameDoor}
            onChange={frameDoorsChanged}
            isDisabled={!isDR}
          />
        </Col>
      </Row>

      <Col
        grow="1"
        width="100%"
        padding="40px 0 72px"
        gap="10px"
        align="center"
      >
        <Row gap="10px">
          <Col justify="flex-end" padding="0 0 20px">
            <Text>Вариант наполнения:</Text>
          </Col>
          <Col justify="flex-start">
            <FillingImg listMain={doorMainList} onClick={showModalDoor} />
          </Col>
        </Row>
        <Row gap="10px" width="300px">
          <Col basis="50%" justify="center">
            <Text right>Тип ручки:</Text>
          </Col>
          <Col basis="50%" width="100%">
            <SelectCashier
              placeholder="Выбрать..."
              options={frameDoorsHandleTypeList}
              value={isDP || isDR ? frameDoorHandleType : null}
              onChange={frameDoorsHandleTypeChanged}
            />
          </Col>
        </Row>
      </Col>

      <Row justify="space-between" align="center">
        <Button
          style={{ maxWidth: '110px', width: '100%', margin: '0' }}
          onClick={() => history.push('/step2')}
        >
          Назад
        </Button>
        <ButtonDark
          style={{ maxWidth: '110px', width: '100%', margin: '0' }}
          onClick={() => history.push('/step4')}
          disabled={!isValidStep}
        >
          Дальше
        </ButtonDark>
      </Row>
    </Col>
  )
}

export const MaterialBox = styled.div`
  cursor: not-allowed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #a1a1a1;
  height: 40px;
  padding-left: 15px;
  margin-bottom: 15px;
`
