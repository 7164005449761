import React from 'react'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Col } from '@lib/styled-components-layout'

export const Input = ({
  autoComplete,
  disabled,
  error,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  width,
}) => (
  <Col width={width || '100%'}>
    {label && <InputLabel>{label}</InputLabel>}
    <InputNative
      autoComplete={autoComplete}
      disabled={disabled}
      error={Boolean(error)}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
    />
    {error && <ErrorLabel>{error}</ErrorLabel>}
  </Col>
)

Input.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
}

Input.defaultProps = {
  autoComplete: undefined,
  disabled: false,
  error: undefined,
  label: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  placeholder: undefined,
  type: 'text',
}

const InputNative = styled.input`
  border: 1px solid #a1a1a1;
  color: #3c3d3e;
  position: relative;
  padding: 0.7rem;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  box-shadow: none;
  background-color: white;
  transition: box-shadow 120ms, border-color 120ms;
  box-shadow: 0 0 0 1px 0 0 0 1px black;

  &:focus {
    box-shadow: 0 0 0 1px black;
    border-color: lightgrey;
  }

  // &:disabled {
  //   background-color: rgba(80, 80, 80, 0.1);
  // }

  ${p =>
    p.error &&
    css`
      box-shadow: 0 0 0 1px red;
      border-color: red;
    `}
`

const InputLabel = styled.label`
  color: currentColor;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
`

const ErrorLabel = styled.label`
  display: block;
  font-size: 0.9em;
  color: red;
  margin-top: 0.2rem;
  text-align: left;
  height: 15px
`
