import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { $modalComponent } from '@features/common'

export const MainTemplate = ({ header, children }) => {
  const { comp: ModalComponent, props } = useStore($modalComponent)

  return (
    <MainContainer>
      <Header>{header}</Header>
      <Main>{children}</Main>
      {ModalComponent && <ModalComponent {...props} />}
    </MainContainer>
  )
}

MainTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

const MainContainer = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  min-height: 100vh;
  max-height: 100vh;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main';
`

const Header = styled.header`
  grid-area: header;
  z-index: 1;
`

const Main = styled.main`
  grid-area: main;
  position: relative;
  max-width: 1340px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  height: auto;
  padding: 25px;
`
