import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useStore } from 'effector-react'
import { Modal } from '@ui/organisms/modal/modal'
import { Row, Col } from '@lib/styled-components-layout'
import { readScope } from '@lib/effector-helpers'
import { Button, Text } from '@ui'

export const CarcassModal = ({ onClose }) => {
  const { selectOptionCarcass, selectModuleWidth, $carcassData } = readScope(
    'carcass',
  )
  const carcassData = useStore($carcassData)

  return (
    <Modal onClose={onClose}>
      <CarcassModalBox>
        {carcassData.map((item, index) => (
          <Item key={index}>
            <img src={item.imgModal} />
            <Row gap="10px" justify="space-between">
              {item.carcass.map((el, index) => (
                <Col key={index}>
                  <Button
                    onClick={useCallback(() => {
                      selectOptionCarcass({
                        key: item.name,
                      })
                      selectModuleWidth({ value: el.moduleWidth })

                      onClose()
                    }, [el])}
                  >
                    <Text>{item.name}</Text>
                    {el.moduleWidth}
                  </Button>
                </Col>
              ))}
            </Row>
          </Item>
        ))}
      </CarcassModalBox>
    </Modal>
  )
}

CarcassModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

const CarcassModalBox = styled.div`
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const Item = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
`
