import React from 'react'
import { readScope } from '@lib/effector-helpers'
import { useStore } from 'effector-react'

import { history } from '@lib/routing'
import { Row, Col } from '@lib/styled-components-layout'
import { CheckoutForm } from '../molecules'
import { Button, ButtonDark, StepTitle } from '@ui'

export const Checkout = () => {
  const { $isValidCheckoutStep } = readScope('checkout')
  const isValidCheckoutStep = useStore($isValidCheckoutStep)

  return (
    <Col width="100%" height="100%">
      <StepTitle title="Шаг 4." titleStep="Оформление" />
      <Col grow="1">
        <CheckoutForm />
      </Col>
      <Row justify="space-between" align="center">
        <Button
          style={{ maxWidth: '110px', width: '100%', margin: '0' }}
          onClick={() => history.push('/step3')}
        >
          Назад
        </Button>
        <ButtonDark
          disabled={!isValidCheckoutStep}
          style={{ maxWidth: '110px', width: '100%', margin: '0' }}
          onClick={() => history.push('/step5')}
        >
          Дальше
        </ButtonDark>
      </Row>
    </Col>
  )
}
