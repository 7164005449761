import { history } from '@lib/routing'
import { accountApi } from '@api/account'
import { loadSession } from '@features/common/model/session.events'
import {
  emailChanged,
  formMounted,
  formSubmitted,
  formUnmounted,
  loginProcessing,
  passwordChanged,
} from './login.events'

import { $email, $password, $form, $isSubmitEnabled } from './login.store'

// Пример мнимого запроса на сервер
const api = (delay, value) =>
  new Promise((resolve, reject) => setTimeout(reject, delay, value))

const exampleFetchResultSuccess = { auth: true }
const exampleFetchResultError = { login: 'Серверная ошибка' }

// ****************************

formMounted.watch(() => {
  console.log('Component mounted')
})

const trimEvent = event => event.target.value.trim()

$email.on(emailChanged.map(trimEvent), (_, email) => email)
$password.on(passwordChanged.map(trimEvent), (_, password) => password)

$form.reset(formUnmounted).reset(formMounted)

formSubmitted.watch(() => {
  if (!$isSubmitEnabled.getState()) return

  const form = $form.getState()
  loginProcessing(form)
})

// loginProcessing.use(form => api(1000, exampleFetchResultError)) // когда мы вызове этот эффект произойдет асинхронный вызов api
loginProcessing.use(form => accountApi.createSession(form))
loginProcessing.done.watch(() => {
  loadSession()
  history.push('/')
})
