import { accountApi } from '@api/account'
import { $session, $isDoneLoadSession } from './session.store'
import { loadSession, sessionDropped } from './session.events'

loadSession.use(() => accountApi.getCurrentAccount())
sessionDropped.use(() => accountApi.dropSession())

$session
  .reset(sessionDropped.done)
  .on(loadSession.done, (_, { result }) => result.user)
  .on(loadSession.fail, () => null)

$isDoneLoadSession.on(loadSession.done, () => true)
