import styled, { css } from 'styled-components'

export const Text = styled.div`
  color: ${p => {
    if (p.light) return 'var(--color-fontLight)'
    if (p.color) return p.color
    return 'var(--color-fontPrimary)'
  }};
  font-size: ${({ size }) => size || '14px'};
  line-height: 17px;
  ${p => {
    if (p.center)
      return css`
        text-align: center;
      `
    if (p.left)
      return css`
        text-align: left;
      `
    if (p.right)
      return css`
        text-align: right;
      `
  }};

  ${p => {
    if (p.black)
      return css`
        font-weight: 900;
      `
    if (p.bold)
      return css`
        font-weight: 700;
      `
  }};
  ${p =>
    p.isActive &&
    css`
      font-weight: 700;
    `};
  ${p =>
    p.isCompleted &&
    css`
      font-weight: 700;
    `};
  margin-bottom: ${p => (p.narrow ? 15 : 0)}px;
`
