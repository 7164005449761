import { request } from '@features/common'

import { mockApiRequest } from './mock'

const getCarcassData = ({ heightCurrent }) =>
  request('GET', `/get-carcass/${heightCurrent}`)

// const getCarcassData = ({ heightCurrent }) =>
//   mockApiRequest(100, {
//     items,
//     optionCarcass,
//   })

const getHeightData = () => request('GET', '/get-height')

// const getHeightData = () => mockApiRequest(100, [2400, 2600])

export const carcassApi = {
  getCarcassData,
  getHeightData,
}

const optionCarcass = [
  {
    carcassName: 'ЛДСП',
    materialCarcass: [
      { material: 'H1137–ST12, ДУБ СОРАНО ЧЁРНО-КОРИЧНЕВЫЙ' },
      { material: 'H3734–ST9, ОРЕХ ДИЖОН НАТУРАЛЬНЫЙ' },
      { material: 'H3702–ST10, ОРЕХ ПАЦИФИК ТАБАК' },
      { material: 'H3430–ST22, СОСНА АЛАНД БЕЛАЯ' },
      { material: 'H3453–ST22, ФЛИТВУД СЕРАЯ ЛАВА' },
      { material: 'H3451–ST22, ФЛИТВУД ШАМПАНЬ' },
      { material: 'H3450–ST22, ФЛИТВУД БЕЛЫЙ' },
    ],
    materialSideWalls: [
      { material: 'H1137–ST12, ДУБ СОРАНО ЧЁРНО-КОРИЧНЕВЫЙ' },
      { material: 'H3734–ST9, ОРЕХ ДИЖОН НАТУРАЛЬНЫЙ' },
      { material: 'H3702–ST10, ОРЕХ ПАЦИФИК ТАБАК' },
      { material: 'H3430–ST22, СОСНА АЛАНД БЕЛАЯ' },
      { material: 'H3453–ST22, ФЛИТВУД СЕРАЯ ЛАВА' },
      { material: 'H3451–ST22, ФЛИТВУД ШАМПАНЬ' },
      { material: 'H3450–ST22, ФЛИТВУД БЕЛЫЙ' },
    ],
  },
  {
    carcassName: 'ЛДСП + ШПОН (масло)',
    materialCarcass: [
      { material: 'U899–ST9, КОСМОС СЕРЫЙ' },
      { material: 'U748–ST9, ТРЮФЕЛЬ КОРИЧНЕВЫЙ' },
      { material: 'U741–ST9, ЛАВА СЕРАЯ' },
      { material: 'U727–ST9, СЕРЫЙ КАМЕНЬ' },
      { material: 'W1000–ST9, БЕЛЫЙ' },
      { material: 'U999–ST2, ЧЁРНЫЙ' },
    ],
    materialSideWalls: [
      { material: 'ШПОН, ДУБ, ПАЛИСАНДР ( масло )' },
      { material: 'ШПОН, ДУБ, ЭБОНИ ( масло )' },
      { material: 'ШПОН, ДУБ, ЦЕМЕНТ ( масло )' },
    ],
  },
  {
    carcassName: 'ЛДСП + ШПОН (лак)',
    materialCarcass: [
      { material: 'U899–ST9, КОСМОС СЕРЫЙ' },
      { material: 'U748–ST9, ТРЮФЕЛЬ КОРИЧНЕВЫЙ' },
      { material: 'U741–ST9, ЛАВА СЕРАЯ' },
      { material: 'U727–ST9, СЕРЫЙ КАМЕНЬ' },
      { material: 'W1000–ST9, БЕЛЫЙ' },
      { material: 'U999–ST2, ЧЁРНЫЙ' },
    ],
    materialSideWalls: [
      { material: 'ШПОН, ДУБ, ОЛЬМОНЕРО ( лак )' },
      { material: 'ШПОН, ДУБ, ЧЁРНЫЙ ( лак )' },
      { material: 'ШПОН, ДУБ, ТАБАК ДЫМЧАТЫЙ ( лак )' },
      { material: 'ШПОН, ДУБ, ШИЛЬФ МАРОН ( лак )' },
      { material: 'ШПОН, ДУБ, РОВЕРА НАТУРАЛ ( лак )' },
      { material: 'ШПОН, ДУБ, СПЕССАРТ ( лак )' },
    ],
  },
]

const items = [
  {
    name: 'K2',
    imgModal: '/images/K2.png',
    img: '/images/K2-noRuler.png',
    filling: {
      main: [1, 1],
      header: [1, 1],
    },
    carcass: [
      {
        moduleWidth: 450,
        commonWidth: 920,
        price: [
          { carcassName: 'ЛДСП', num: 6180 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 8630 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 11160 },
        ],
      },
      {
        moduleWidth: 500,
        commonWidth: 1020,
        price: [
          { carcassName: 'ЛДСП', num: 6381 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 8857 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 11326 },
        ],
      },
      {
        moduleWidth: 550,
        commonWidth: 1120,
        price: [
          { carcassName: 'ЛДСП', num: 6618 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 9126 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 11614 },
        ],
      },
    ],
  },
  {
    name: 'K3L',
    imgModal: '/images/K3L.png',
    img: '/images/K3L-noRuler.png',
    filling: {
      main: [1, 1, 1],
      header: [1, 1, 1],
    },
    carcass: [
      {
        moduleWidth: 450,
        commonWidth: 1370,
        price: [
          { carcassName: 'ЛДСП', num: 9135 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 11996 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 14674 },
        ],
      },
      {
        moduleWidth: 500,
        commonWidth: 1520,
        price: [
          { carcassName: 'ЛДСП', num: 9420 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 12257 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 14933 },
        ],
      },
      {
        moduleWidth: 550,
        commonWidth: 1670,
        price: [
          { carcassName: 'ЛДСП', num: 9711 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 12648 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 15369 },
        ],
      },
    ],
  },
  {
    name: 'K3R',
    imgModal: '/images/K3R.png',
    img: '/images/K3R-noRuler.png',
    filling: {
      main: [1, 1, 1],
      header: [1, 1, 1],
    },
    carcass: [
      {
        moduleWidth: 450,
        commonWidth: 1370,
        price: [
          { carcassName: 'ЛДСП', num: 9135 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 11996 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 14674 },
        ],
      },
      {
        moduleWidth: 500,
        commonWidth: 1520,
        price: [
          { carcassName: 'ЛДСП', num: 9420 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 12257 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 14933 },
        ],
      },
      {
        moduleWidth: 550,
        commonWidth: 1670,
        price: [
          { carcassName: 'ЛДСП', num: 9711 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 12648 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 15369 },
        ],
      },
    ],
  },
  {
    name: 'K4T1',
    imgModal: '/images/K4T1.png',
    img: '/images/K4T1-noRuler.png',
    filling: {
      main: [1, 1, 1, 1],
      header: [1, 1, 1, 1],
    },
    carcass: [
      {
        moduleWidth: 450,
        commonWidth: 1370,
        price: [
          { carcassName: 'ЛДСП', num: 10935 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 13991 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 16794 },
        ],
      },
      {
        moduleWidth: 500,
        commonWidth: 1520,
        price: [
          { carcassName: 'ЛДСП', num: 11334 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 14362 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 17162 },
        ],
      },
      {
        moduleWidth: 550,
        commonWidth: 1670,
        price: [
          { carcassName: 'ЛДСП', num: 11727 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 14787 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 17612 },
        ],
      },
    ],
  },
  {
    name: 'K4T2',
    imgModal: '/images/K4T2.png',
    img: '/images/K4T2-noRuler.png',
    filling: {
      main: [1, 1, 1, 1],
      header: [1, 1, 1, 1],
    },
    carcass: [
      {
        moduleWidth: 450,
        commonWidth: 1370,
        price: [
          { carcassName: 'ЛДСП', num: 12045 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 15249 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 18126 },
        ],
      },
      {
        moduleWidth: 500,
        commonWidth: 1520,
        price: [
          { carcassName: 'ЛДСП', num: 12438 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 15620 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 18494 },
        ],
      },
      {
        moduleWidth: 550,
        commonWidth: 1670,
        price: [
          { carcassName: 'ЛДСП', num: 12816 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 16028 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 18922 },
        ],
      },
    ],
  },
  {
    name: 'K5L',
    imgModal: '/images/K5L.png',
    img: '/images/K5L-noRuler.png',
    filling: {
      main: [1, 1, 1, 1, 1],
      header: [1, 1, 1, 1, 1],
    },
    carcass: [
      {
        moduleWidth: 450,
        commonWidth: 1370,
        price: [
          { carcassName: 'ЛДСП', num: 14025 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 17473 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 20495 },
        ],
      },
      {
        moduleWidth: 500,
        commonWidth: 1520,
        price: [
          { carcassName: 'ЛДСП', num: 14526 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 17925 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 20931 },
        ],
      },
      {
        moduleWidth: 550,
        commonWidth: 1670,
        price: [
          { carcassName: 'ЛДСП', num: 15009 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 18445 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 21482 },
        ],
      },
    ],
  },
  {
    name: 'K5R',
    imgModal: '/images/K5R.png',
    img: '/images/K5R-noRuler.png',
    filling: {
      main: [1, 1, 1, 1, 1],
      header: [1, 1, 1, 1, 1],
    },
    carcass: [
      {
        moduleWidth: 450,
        commonWidth: 1370,
        price: [
          { carcassName: 'ЛДСП', num: 14025 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 17473 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 20495 },
        ],
      },
      {
        moduleWidth: 500,
        commonWidth: 1520,
        price: [
          { carcassName: 'ЛДСП', num: 14526 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 17925 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 20931 },
        ],
      },
      {
        moduleWidth: 550,
        commonWidth: 1670,
        price: [
          { carcassName: 'ЛДСП', num: 15009 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 18445 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 21482 },
        ],
      },
    ],
  },
  {
    name: 'K6',
    imgModal: '/images/K6.png',
    img: '/images/K6-noRuler.png',
    filling: {
      main: [1, 1, 1, 1, 1, 1],
      header: [1, 1, 1, 1, 1, 1],
    },
    carcass: [
      {
        moduleWidth: 450,
        commonWidth: 1370,
        price: [
          { carcassName: 'ЛДСП', num: 15702 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 19360 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 22515 },
        ],
      },
      {
        moduleWidth: 500,
        commonWidth: 1520,
        price: [
          { carcassName: 'ЛДСП', num: 16296 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 19870 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 22994 },
        ],
      },
      {
        moduleWidth: 550,
        commonWidth: 1670,
        price: [
          { carcassName: 'ЛДСП', num: 16902 },
          { carcassName: 'ЛДСП + ШПОН (масло)', num: 20526 },
          { carcassName: 'ЛДСП + ШПОН (лак)', num: 23685 },
        ],
      },
    ],
  },
]
