import { combine } from 'effector'

export const initiateTotalPriceModel = ({
  carcass,
  filling,
  door,
  checkout,
}) => {
  const { $commonPrice, $ledPrice } = filling
  const { $priceCarcass } = carcass
  const { $priceDoor } = door
  const { $checkoutInfo } = checkout

  const $cupboardPrice = combine(
    $commonPrice,
    $ledPrice,
    $priceCarcass,
    $priceDoor,
    (commonPrice, ledPrice, priceCarcass, priceDoor) => {
      const cupboardPrice = [
        commonPrice,
        ledPrice,
        priceCarcass,
        priceDoor,
      ].reduce((acc, cur) => acc + cur, 0)

      return cupboardPrice
    },
  )

  const $discountPercent = $checkoutInfo.map(({ discount }) => discount || 0)
  const $discount = combine(
    $cupboardPrice,
    $discountPercent,
    (cupboardPrice, value) => {
      const discount = Math.round((cupboardPrice / 100) * value)

      return discount
    },
  )

  const $totalPrice = combine(
    $cupboardPrice,
    $discount,
    (cupboardPrice, discount) => {
      const totalPrice = cupboardPrice - discount

      return totalPrice
    },
  )

  return {
    $totalPrice,
    $cupboardPrice,
    $discount,
  }
}
