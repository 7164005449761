import { setStoreName } from 'effector' 

export function initLocalStorage(store, name) {
  if (name) {
    setStoreName(store, name)
  }

  const snapshot = localStorage.getItem(
    name ? store.compositeName.shortName : store.defaultConfig.sid,
  )
  if (snapshot !== null) store.setState(JSON.parse(snapshot))

  store.updates.watch(newState => {
    localStorage.setItem(
      name ? store.compositeName.shortName : store.defaultConfig.sid,
      JSON.stringify(newState),
    )
  })

  return store
}
