import React, { Fragment, useCallback } from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

const widthItem = 44

// eslint-disable-next-line react/prop-types
export const FillingImg = ({ listMain, header, onClick }) => {
  return (
    <Fragment>
      {header && <ItemBox>{header}</ItemBox>}
      <ItemBox>
        {listMain.map(({ selectKey, size, img }, index) => (
          <ItemMain key={index} style={{ width: `${size * widthItem}px` }}>
            {selectKey && <Img src={img} />}
          </ItemMain>
        ))}
      </ItemBox>
      <ItemBox>
        {listMain.map(({ size, selectKey }, index) => (
          <ItemBtn
            onClick={() => onClick(index)}
            key={index}
            style={{ width: `${size * widthItem}px` }}
          >
            <Span>{selectKey || '+'}</Span>
          </ItemBtn>
        ))}
      </ItemBox>
    </Fragment>
  )
}

FillingImg.propTypes = {
  header: PropTypes.node,
  listMain: PropTypes.array.isRequired,
}

FillingImg.defaultProps = {
  header: null,
}

const Img = styled.img`
  height: 100%;
`

export const ItemBox = styled.ul`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
`

export const ItemMain = styled.li`
  height: 194px;
  list-style-type: none;
  border: 1px solid #a1a1a1;
  cursor: pointer;
  margin: 0 1px;
`

const Span = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemBtn = styled.li`
  height: 44px;
  list-style-type: none;
  border: 1px solid #a1a1a1;
  cursor: pointer;
  margin: 0 1px;
`
