import {
  useClearOnUnmount,
  createInputField,
  createForm,
  initLocalStorage,
} from '@lib/effector-helpers'
import { emailValidator, phoneValidator } from '@features/join'

export const initiateCheckoutModel = () => {
  const firstNameField = createInputField({
    name: 'firstName',
    validator: ({ value }) => {
      if (value && value.length > 3) return null
      return 'Введите имя'
    },
  })

  initLocalStorage(firstNameField.$value, 'firstNameField')

  const lastNameField = createInputField({
    name: 'lastName',
    validator: ({ value }) => {
      if (value && value.length > 3) return null
      return 'Введите фамилию'
    },
  })

  initLocalStorage(lastNameField.$value, 'lastNameField')

  const phoneField = createInputField({
    name: 'phone',
    // validator: phoneValidator,
  })

  initLocalStorage(phoneField.$value, 'phoneField')

  const emailField = createInputField({
    name: 'email',
    validator: ({ value, isChanged }) => {
      if (isChanged) {
        return emailValidator({ value })
      }
      return null
    },
  })

  initLocalStorage(emailField.$value, 'emailField')

  const dealerNameField = createInputField({
    name: 'dealerName',
    validator: ({ value }) => {
      if (value && value.length > 3) return null
      return 'Введите ФИО диллера'
    },
  })

  initLocalStorage(dealerNameField.$value, 'dealerNameField')

  const addressField = createInputField({
    name: 'address',
    validator: ({ value }) => {
      if (value && value.length > 3) return null
      return 'Введите адрес'
    },
  })

  initLocalStorage(addressField.$value, 'addressField')

  const commentField = createInputField({
    name: 'comment',
  })

  initLocalStorage(commentField.$value, 'commentField')

  const discountField = createInputField({
    name: 'discount',
  })

  initLocalStorage(discountField.$value, 'discountField')

  const fields = [
    firstNameField,
    lastNameField,
    phoneField,
    emailField,
    dealerNameField,
    addressField,
    commentField,
    discountField,
  ]

  const dataCheckout = createForm({
    fields,
    handleSubmit: () => Promise.resolve('success'),
    handleSuccess: () => {},
  })
  const {
    $value,
    $isSubmitEnabled: $isValidCheckoutStep,
    formReset,
  } = dataCheckout

  //* *************************************************validationStep

  return {
    firstNameField,
    lastNameField,
    phoneField,
    emailField,
    dealerNameField,
    addressField,
    discountField,
    commentField,
    $checkoutInfo: $value,
    $isValidCheckoutStep,
    resetCheckout: formReset,
  }
}
