// @flow
import { type Store, createStore } from 'effector'
import { type CurrentAccount } from '@api/account'

export const $session: Store<?CurrentAccount> = createStore(null)

$session.watch(el => console.log('Session:', el))

export const $isAuthenticated: Store<boolean> = $session.map(
  session => session !== null,
)

export const $role = $session.map(session => session && session.role)

export const $isDoneLoadSession = createStore(false)
