const emailRegexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
export const emailValidator = ({ value }) => {
  if (!emailRegexp.test(value)) return 'Адрес эл. почты введен неправильно!'
  return null
}

const passwordRegexp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/
export const passwordValidator = ({ value }) => {
  if (!passwordRegexp.test(value))
    return 'Your password should include at least uppercase, lowercase, number and special symbol'

  return null
}

const phoneRegexp = /^\d[\d\(\)\ -]{4,14}\d$/
export const phoneValidator = ({ value }) => {
  if (!phoneRegexp.test(value)) return 'Номер телефона введен неправильно!'
  return null
}
