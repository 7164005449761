import React from 'react'

import { AdminPage } from './page'

export const adminRoutes = () => [
  {
    path: '/admin',
    component: AdminPage,
    routes: [
      {
        path: '/admin',
        exact: true,
        component: () => <h1>admin</h1>,
      },
      {
        path: '/stats',
        exact: true,
        component: () => <h1>stats</h1>,
      },
    ],
  },
]
