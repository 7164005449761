/* eslint-disable no-shadow */
import { createEvent, sample, createStoreObject } from 'effector'
import { history } from '@lib/routing'

import { useClearOnUnmount } from '@lib/effector-helpers'

import { logoutPressed } from '@features/join/model/logout.model'

export const initiateSuccessStepModel = ({
  carcass,
  filling,
  door,
  checkout,
}) => {
  const { resetCarcassHeight, $isValidCarcassStep, resetCarcass } = carcass
  const { resetFilling, $isValidFillingStep } = filling
  const { resetDoor, $isValidDoorStep } = door
  const { resetCheckout, $isValidCheckoutStep } = checkout

  const redirectToCurrentStep = createEvent()

  sample({
    source: createStoreObject({
      $isValidCarcassStep,
      $isValidFillingStep,
      $isValidDoorStep,
      $isValidCheckoutStep,
    }),
    clock: redirectToCurrentStep,
    fn: ({
      $isValidCarcassStep,
      $isValidFillingStep,
      $isValidDoorStep,
      $isValidCheckoutStep,
    }) => {
      if ($isValidCheckoutStep) return history.replace('/step5')
      if ($isValidDoorStep) return history.replace('/step4')
      if ($isValidFillingStep) return history.replace('/step3')
      if ($isValidCarcassStep) {
        return history.replace('/step2')
      }
      return history.replace('/step1')
    },
  })

  const arrayEvents = createEvent()

  arrayEvents.watch(() => {
    resetCheckout()
    resetDoor()
    resetFilling()
    resetCarcassHeight()
    resetCarcass()
  })

  logoutPressed.watch(() => {
    console.log('reset')
    resetCheckout()
    resetDoor()
    resetFilling()
    resetCarcassHeight()
    resetCarcass()
  })

  arrayEvents.watch(() => console.log('reset '))

  return {
    arrayEvents,
    redirectToCurrentStep,
  }
}
