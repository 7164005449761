import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { Row, Col } from '@lib/styled-components-layout'

import styled from 'styled-components'
import { InputLabel, InputTooltip } from './input-cashier'

export const SelectCashier = ({
  label,
  tooltip,
  description,
  options,
  value,
  onChange,
  ...rest
}) => {
  console.log('value', value)

  return (
    <Col width="100%" style={{ position: 'relative' }}>
      <InputLabel>{label}</InputLabel>
      <Row align="center" width="100%">
        <Col
          width="100%"
          style={{ position: 'relative', marginBottom: '15px' }}
        >
          <StyledSelect
            classNamePrefix="Select"
            options={options}
            onChange={onChange}
            value={value ? { value, label: value } : null}
            {...rest}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#e2e0e0',
                primary: '#a1a1a1',
              },
            })}
          />
          {tooltip && <InputTooltip>{tooltip}</InputTooltip>}
        </Col>
        {/* <InputDescription>{description}</InputDescription> */}
      </Row>
    </Col>
  )
}

SelectCashier.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.string,
  options: PropTypes.shape({}).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  value: PropTypes.string,
}

SelectCashier.defaultProps = {
  label: undefined,
  tooltip: undefined,
  description: undefined,
  onChange: undefined,
  value: undefined,
}

const StyledSelect = styled(Select)`
  .Select__control {
    height: 40px;
    width: 100%;
    border: 1px solid #a1a1a1;
    border-radius: 0;
    cursor: pointer;
  }

  .Select__control:hover {
    border-color: #a1a1a1;
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 1px black;
    outline: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    color: #3c3d3e;
  }

  .Select__menu-list {
    padding: 0;
  }

  .Select__option:active {
    background-color: #808080;
  }
`
