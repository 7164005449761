import { useEffect } from 'react'

export const CommonLoader = ({ children }) => {
  useEffect(() => {
    // test
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}
