import React from 'react'

import { Col } from '@lib/styled-components-layout'
import { OrderComment, OrderDescription } from '../molecules'
import { H2 } from '@ui'

export const OrderInfo = () => {
  return (
    <Col width="100%">
      <H2>Описание заказа</H2>
      <OrderDescription />
      <OrderComment />
    </Col>
  )
}
