import React from 'react'
import { useStore } from 'effector-react'

import { Row, Col } from '@lib/styled-components-layout'

import { showModal } from '@features/common'

import { history } from '@lib/routing'
import { readScope } from '@lib/effector-helpers'
import { CarcassModal } from '../molecules'
import {
  SelectCashier,
  Text,
  ButtonShowModal,
  StepTitle,
  Arrow,
  ButtonDark,
} from '@ui'

const showModalCarcass = () => showModal({ comp: CarcassModal })

export const Carcass = () => {
  const {
    $heightList,
    $moduleWidthList,
    selectModuleWidth,

    $optionCarcassList,
    $materialCarcassList,
    $materialSideWallsList,

    heightChanged,
    optionCarcassChanged,
    materialCarcassChanged,
    materialSideWallsChanged,
    $heightCurrent,
    $optionCarcassCurrent,
    $materialCarcassCurrent,
    $materialSideWallsCurrent,

    $currentCarcass,
    $currentModuleWidth,

    $isDisabledMaterialCarcass,
    $isValidCarcassStep,
  } = readScope('carcass')

  const heightOptions = useStore($heightList)
  const height = useStore($heightCurrent)

  const option = useStore($optionCarcassCurrent)
  const options = useStore($optionCarcassList)

  const material = useStore($materialCarcassCurrent)
  const materialOptions = useStore($materialCarcassList)

  const side = useStore($materialSideWallsCurrent)
  const sideOptions = useStore($materialSideWallsList)

  const { name = '' } = useStore($currentCarcass) || {}
  const { img = '' } = useStore($currentCarcass) || {}
  const { moduleWidth = '', commonWidth = '' } =
    useStore($currentModuleWidth) || {}
  const moduleWidthOptions = useStore($moduleWidthList)

  const isDisabledMaterialCarcass = useStore($isDisabledMaterialCarcass)

  const isValidStep = useStore($isValidCarcassStep)

  return (
    <Col width="100%" height="100%">
      <StepTitle title="Шаг 1." titleStep="Выбор корпуса" />
      <Row
        width="100%"
        justify="space-between"
        padding="0 0 15px 0"
        style={{ borderBottom: '1px solid #A1A1A1', boxSizing: 'border-box' }}
      >
        <Col style={{ maxWidth: '240px', width: '100%', height: '97px' }}>
          <SelectCashier
            options={heightOptions}
            placeholder="Выбрать..."
            label="Вариант высоты (мм)"
            value={height}
            onChange={heightChanged}
          />
        </Col>

        <Col
          justify="center"
          style={{ maxWidth: '240px', width: '100%', height: '97px' }}
        >
          <Text style={{ marginBottom: '8px' }}>Вариант ширины</Text>
          <ButtonShowModal
            disabled={Boolean(!height)}
            onClick={showModalCarcass}
          >
            {name || 'Выбрать'}
            <Arrow />
          </ButtonShowModal>
          <Row>
            <Text style={{ marginRight: '5px' }}>Код корпуса:</Text>
            <Text bold>{`702.${moduleWidth}-${name}`}</Text>
          </Row>
        </Col>

        <Col style={{ maxWidth: '240px', width: '100%', height: '97px' }}>
          <SelectCashier
            options={moduleWidthOptions}
            placeholder="Выбрать..."
            label="Модуль ширины (мм)"
            value={moduleWidth}
            onChange={selectModuleWidth}
            isDisabled={Boolean(!name)}
          />
          <Row>
            <Text style={{ marginRight: '5px' }}>Ширина корпуса:</Text>
            <Text bold>{`${commonWidth} мм`}</Text>
          </Row>
        </Col>
      </Row>

      <Row
        grow="1"
        width="100%"
        padding="40px 0 150px 0"
        align="center"
        style={{ height: '100%' }}
      >
        <Col style={{ maxWidth: '370px', width: '100%' }}>
          <SelectCashier
            options={options}
            placeholder="Выбрать..."
            label="Вариант исполнения корпуса"
            value={option}
            onChange={optionCarcassChanged}
            isDisabled={Boolean(!moduleWidth)}
          />

          <SelectCashier
            options={materialOptions}
            placeholder="Выбрать..."
            label="Материал корпуса"
            value={material}
            onChange={materialCarcassChanged}
            isDisabled={!isDisabledMaterialCarcass}
          />

          {
            option === 'ЛДСП' ?
              <SelectCashier
                options={sideOptions}
                placeholder="Выбрать..."
                label="Материал боковых стенок"
                value={side}
                onChange={materialSideWallsChanged}
                isDisabled
              />  :
              <SelectCashier
                options={sideOptions}
                placeholder="Выбрать..."
                label="Материал боковых стенок"
                value={side}
                onChange={materialSideWallsChanged}
                isDisabled={!isDisabledMaterialCarcass}
              />
          }

        </Col>

        <Col style={{ maxWidth: '370px', width: '100%' }}>
          <img src={img} alt="" />
        </Col>
      </Row>
      <Row justify="flex-end" align="center">
        <ButtonDark
          style={{ maxWidth: '110px', width: '100%', margin: '0' }}
          onClick={() => history.push('/step2')}
          disabled={!isValidStep}
        >
          Дальше
        </ButtonDark>
      </Row>
    </Col>
  )
}