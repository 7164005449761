import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

// import { Icon } from '@ui/atoms'

const widthItem = 44

export const Header = ({ listHeader, onClick }) => {
  return (
    <>
      {listHeader.map(({ size, isActive }, index) => {
        return (
          <ItemHeader
            isActive={isActive}
            key={index}
            style={{ width: `${size * widthItem}px` }}
            onClick={() => onClick(index)}
          >
            {isActive && <img src={'/images/light-bulb.png'} alt='bulb' />}
          </ItemHeader>
        )
      })}
    </>
  )
}

Header.propTypes = {
  listHeader: PropTypes.array.isRequired,
}

export const ItemHeader = styled.li`
  height: 34px;
  list-style-type: none;
  border: 1px solid #a1a1a1;
  cursor: pointer;
  margin: 0 1px;
  display: flex;
  justify-content: center;
  align-items: center;
`
