import React from 'react'
import { renderRoutes } from 'react-router-config'

import { NotFoundPage } from '@pages/not-found'
import { homeRoutes } from '@pages/home'
import { joinRoutes } from '@pages/join'
import { adminRoutes } from '@pages/admin'

const routes = [
  // other routes
  ...joinRoutes(),
  ...adminRoutes(),
  ...homeRoutes(),
  { component: NotFoundPage },
]

export const Routes = () => <>{renderRoutes(routes)}</>
