import React from 'react'
import { useStore } from 'effector-react'

import { Input, H2, ButtonDark, Card } from '@ui/atoms'
import styled from 'styled-components'
import {
  $email,
  $password,
  $isSubmitEnabled,
  $emailError,
  $passwordError,
  $isFormDisabled,
} from '../model/login.store'
import {
  emailChanged,
  passwordChanged,
  formSubmitted,
  loginFetching,
} from '../model/login.events'

const handleSubmit = event => {
  event.preventDefault()
  formSubmitted()
}

export const LoginForm = () => {
  const isSubmitEnabled = useStore($isSubmitEnabled)
  const formError = useStore(loginFetching.error)

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <H2>Войдите в систему</H2>
        {formError && <ErrorBox>{formError.login}</ErrorBox>}
        <Email />
        <Password />
        <ButtonDark disabled={!isSubmitEnabled} type="submit">
          Войти
        </ButtonDark>
      </form>
    </Card>
  )
}

const Email = () => {
  const email = useStore($email)
  const isFormDisabled = useStore($isFormDisabled)
  const emailError = useStore($emailError)

  return (
    <InputBox>
      <Input
        type="email"
        name="email"
        autoComplete="off"
        label="Имя пользователя"
        disabled={isFormDisabled}
        onChange={emailChanged}
        value={email}
        error={email && emailError}
      />
    </InputBox>
  )
}

const Password = () => {
  const password = useStore($password)
  const isFormDisabled = useStore($isFormDisabled)
  const passwordError = useStore($passwordError)

  return (
    <InputBox>
      <Input
        type="password"
        name="password"
        autoComplete="off"
        label="Пароль"
        disabled={isFormDisabled}
        onChange={passwordChanged}
        value={password}
        error={password && passwordError}
      />
    </InputBox>
  )
}

export const InputBox = styled.div`
  height: 100px;
`

export const ErrorBox = styled.div`
  display: block;
  border: 1px solid red;
  padding: 0.4rem 0.6rem;
  color: red;
  border-radius: 0.4rem;
`
