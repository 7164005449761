import React from 'react'
import PropTypes from 'prop-types'

import { MainTemplate, CenterContentTemplate } from '@ui/templates'

import { useStore } from 'effector-react'
import { Header } from '../organisms'
import { $isDoneLoadSession } from '../model/session.store'
import styled, { keyframes } from 'styled-components'

export const CommonContentTemplate = ({ header, children }) => {
  const isDoneLoadSession = useStore($isDoneLoadSession)

  return isDoneLoadSession ? (
    <MainTemplate header={header}>{children}</MainTemplate>
  ) : (
    <CenterContentTemplate>
      <Loader>
        <Item style={{backgroundColor: '#a1a1a1', animationDelay: '-1.5s'}}></Item>
        <Item style={{backgroundColor: '#323232', animationDelay: '-1s'}}></Item>
        <Item style={{backgroundColor: '#a1a1a1', animationDelay: '-0.5s'}}></Item>
        <Item style={{backgroundColor: '#323232'}}></Item>
      </Loader>
    </CenterContentTemplate>
  )
}

CommonContentTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
}

CommonContentTemplate.defaultProps = {
  header: <Header />,
}

const rotate = keyframes`
  0%,100%{transform:translate(0)}
  25%{transform:translate(160%)}
  50%{transform:translate(160%, 160%)}
  75%{transform:translate(0, 160%)}
`
const Loader = styled.div`
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  width:50px;
  height:50px;
}
`

const Item = styled.div`
  border: 0;
    margin: 0;
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    animation: ${rotate} 2s ease infinite
`

