import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  /* border-radius: 4px; */
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(169, 206, 227, 0.25);
  background-color: #ffffff;
`

export const CardSticky = styled(Card)`
  position: sticky;
  top: 2rem;
`

export const CardNarrow = styled(Card)`
  padding: 0;
`
