import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { Modal } from '@ui/organisms/modal/modal'
import { readScope } from '@lib/effector-helpers'
import { Button } from '@ui'

export const FillingModal = ({ onClose, index }) => {
  const { selectFillingMain, $fillingOptionsList } = readScope('filling')
  const filling = useStore($fillingOptionsList)
  return (
    <Modal
      onClose={onClose}
      title="Выбирите варианты наполнения проёмов корпуса"
    >
      <FillingModalBox>
        {filling.map(item => (
          <Item key={item}>
            <img src={item.img} />
            <Button
              onClick={useCallback(() => {
                selectFillingMain({
                  index,
                  key: item.name,
                  img: item.img,
                })
                onClose()
              }, [item.name])}
            >
              {item.name}
            </Button>
          </Item>
        ))}
      </FillingModalBox>
    </Modal>
  )
}

FillingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

const FillingModalBox = styled.ul`
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const Item = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
`
