import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Col, Row } from '@lib/styled-components-layout'

import { WithThemeToggler } from '@lib/theme-context'
import { WithAccount } from './with-account'
import { Logo } from '@ui'

export const Header = () => (
  <HeaderBox>
    <NavLink to="/">
      <Logo />
    </NavLink>
    <Row gap="15px">
      <Navigation />
      {/* <ToggleThemeButton /> */}
    </Row>
  </HeaderBox>
)

export const HeaderEmpty = () => (
  <HeaderBox>
    <NavLink to="/">
      <Logo />
    </NavLink>
    {/* <ToggleThemeButton /> */}
  </HeaderBox>
)

const Navigation = () => (
  <WithAccount renderExists={linksForUser} renderEmpty={linksForAnonym} />
)

// eslint-disable-next-line react/prop-types
const linksForUser = ({ role }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const isAdmin = React.useMemo(() => role === 'admin', [role])

  return (
    <>
      {role === 'admin' && (
        <>
          {/*<NavLink to="/admin">Admin</NavLink>*/}
          {/*<NavLink to="/stats">Stats</NavLink>*/}
          {/*<NavLink to="/step1">New Order</NavLink>*/}
        </>
      )}

      <NavLink to="/logout">Выйти</NavLink>
    </>
  )
}

const linksForAnonym = () => <NavLink to="/login">Join</NavLink>

const ToggleThemeButton = () => (
  <WithThemeToggler
    render={({ toggle, isDark }) => (
      <NavItem onClick={toggle}>{isDark ? '🌔' : '☀️'}</NavItem>
    )}
  />
)

const HeaderBox = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  justify-content: space-between;
  padding: 0 30px;
  z-index: 1000;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(36, 37, 38, 0.08);
  background-color: #323232;
`

const NavItem = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  font-family: Lato, sans-serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 19px;
  color: #ffffff;
  &:hover {
    color: grey;
  }
`

const NavLink = NavItem.withComponent(Link)
