import { createEvent } from 'effector'

import { sessionDropped } from '@features/common'
import { history } from '@lib/routing'

export const logoutPressed = createEvent()
export const cancelPressed = createEvent()

cancelPressed.watch(() => {
  history.push('/')
})

logoutPressed.watch(() => {
  setTimeout(() => sessionDropped(), 0)
})

sessionDropped.done.watch(() => {
  history.push('/login')
})
