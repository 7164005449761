import {
  sample,
  createStore,
  combine,
  createEvent,
  createEffect,
} from 'effector'

import { createFetching } from '@lib/fetching'

export const createInputField = ({
  name,
  validator = () => {},
  initialValue = '',
}) => {
  const trimEvent = event => event.target.value

  // Create stores and events
  const $initialValue = createStore(initialValue)
  const $value = createStore(initialValue)
  const $isFocus = createStore(false)
  const $customError = createStore(null)
  const $isChanged = combine(
    $value,
    $initialValue,
    // eslint-disable-next-line no-shadow
    (value, initialValue) => value !== initialValue,
  )

  const onChange = createEvent()
  const onBlur = createEvent()
  const onFocus = createEvent()
  const reset = createEvent()
  const setChanged = createEvent()
  const setValue = createEvent()
  const setInitValue = createEvent()
  const setError = createEvent()

  $initialValue.on(sample($value, setInitValue), (_, value) => value)
  $value
    .on(onChange.map(trimEvent), (_, value) => value)
    .on(setValue, (_, value) => value)
    .reset(reset)
  $customError.on(setError, (_, error) => error).reset(onFocus)
  $isChanged.on(setChanged, (_, value) => value).on(onChange, () => true)
  $isFocus.on(onFocus, () => true).on(onBlur, () => false)

  const $validatorError = combine(
    $value,
    $isFocus,
    $isChanged,
    (value, isFocus, isChanged) => validator({ value, isFocus, isChanged }),
  )

  const $error = combine(
    $validatorError,
    $customError,
    (validatorEr, customError) => {
      if (customError) return customError
      if (validatorEr) return validatorEr
      return null
    },
  )

  return {
    name,
    $value,
    $isFocus,
    $error,
    $isChanged,
    $customError,
    $initialValue,
    onChange,
    onBlur,
    onFocus,
    setError,
    setValue,
    reset,
    events: {
      onChange,
      onBlur,
      onFocus,
      name,
    },
    $stores: combine({
      value: $value,
      error: $error,
      isFocus: $isFocus,
      isChanged: $isChanged,
    }),
  }
}

export const createForm = ({ fields, handleSubmit, handleSuccess }) => {
  // Stores
  const $form = combine(
    fields.reduce((acc, { name, $value }) => ({ ...acc, [name]: $value }), {}),
  )

  const $isFormValid = combine(...fields.map(el => el.$error), (...args) =>
    args.every(el => !el),
  )

  const inputFields = fields.reduce(
    (acc, el) => ({ ...acc, [el.name]: el }),
    {},
  )

  // Events
  const handleProcessing = createEffect()
  const formSubmitted = createEvent()
  const formReset = createEvent()
  const formPhoneMounted = createEvent()
  const formPhoneUnmounted = createEvent()

  $form.reset(formPhoneUnmounted, formPhoneMounted, formReset)

  const handleSuccessDone = ({ result }) =>
    handleSuccess({ result, form: inputFields })

  handleProcessing.use(handleSubmit)

  handleProcessing.done.watch(handleSuccessDone)

  const fetching = createFetching(handleProcessing)
  const $isFormDisabled = fetching.isLoading

  formSubmitted.watch(() => {
    if ($isFormDisabled.getState()) return

    const form = $form.getState()
    handleProcessing(form)
  })

  formReset.watch(() => {
    fields.forEach(input => input.reset())
  })

  const $isSubmitEnabled = combine(
    $isFormValid,
    fetching.isLoading,
    (isFormValid, isFetching) => isFormValid && !isFetching,
  )

  return {
    $value: $form,
    formReset,
    inputFields,
    formPhoneMounted,
    formPhoneUnmounted,
    formSubmitted,
    fetching,
    $isSubmitEnabled,
    $isFormDisabled,
  }
}
