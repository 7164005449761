import React from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import { ButtonDark, Button } from '@ui'

export const StepBtn = ({
  onForward,
  onBack,
  resetCounter,
  isChanged,
  isHide,
}) => {
  return (
    <StepBtnBox>
      {isHide ? (
        <Button onClick={onBack}>
          Назад
        </Button>
      ) : (
        <Button onClick={onBack}>Назад</Button>
      )}
      {isChanged ? (
        <ButtonDark onClick={resetCounter}>Сохранить</ButtonDark>
      ) : (
        <ButtonDark onClick={onForward}>Дальше</ButtonDark>
      )}
    </StepBtnBox>
  )
}

StepBtn.propTypes = {
  onForward: PropTypes.func,
  onBack: PropTypes.func,
}

StepBtn.defaultProps = {
  onForward: undefined,
  onBack: undefined,
}

const StepBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ButtonDark} {
    max-width: 110px;
    width: 100%;
    margin-top: 0;
  }
  ${Button} {
    max-width: 110px;
    width: 100%;
    margin: 0;
    opacity: 0;
    pointer-events: none;
  }
`
