import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const CenterContentTemplate = ({ children, header, isFull }) => (
  <CenterContent isFull={isFull}>
    {header}
    {children}
  </CenterContent>
)

CenterContentTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  isFull: PropTypes.bool,
}

CenterContentTemplate.defaultProps = {
  header: null,
  isFull: null,
}

export const CenterContent = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;

  ${p =>
    p.isFull &&
    css`
      height: 100vh;
    `}
`
