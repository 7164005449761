import { LoginPage } from './login'
import { LogoutPage } from './logout'

export const joinRoutes = () => [
  {
    path: '/login',
    exact: true,
    component: LoginPage,
  },
  {
    path: '/logout',
    exact: true,
    component: LogoutPage,
  },
]
