import {
  createStore,
  createEvent,
  combine,
  forward,
  createEffect,
} from 'effector'
import { propEq, find, adjust } from 'ramda'

import { initLocalStorage } from '@lib/effector-helpers'
import { createFetching } from '@lib/fetching'
import { fillingApi } from '@api/filling'

export const initiateFillingModel = ({ carcass }) => {
  const { $currentModuleWidth, $currentFilling, $heightCurrent } = carcass

  // Events
  const selectFillingMain = createEvent()
  const selectFillingHeader = createEvent()
  const resetFilling = createEvent()

  // Effects
  const loadFillingData = createEffect({
    handler: ({ heightCurrent }) =>
      fillingApi.getFillingData({ heightCurrent }),
  })
  const loadFillingDataFetching = createFetching(loadFillingData)

  // Stores
  const $priceFilling = createStore([])
  const $priceLed = createStore([])

  $priceFilling.on(loadFillingData.done, (_, { result }) => result.priceFilling)
  $priceLed.on(loadFillingData.done, (_, { result }) => result.priceLed)

  $heightCurrent.updates.watch(value => {

    resetFilling()
    loadFillingData({ heightCurrent: value })
  })

  //* ******************************

  const $fillingLedList = combine(
    $priceLed,
    $currentModuleWidth,
    (priceLed, currentModuleWidth) => {
      const { price = [] } = currentModuleWidth
        ? find(propEq('moduleWidth', currentModuleWidth.moduleWidth))(
            priceLed,
          ) || {}
        : []
      return price
    },
  )

  // *****************************

  const $fillingOptionsList = combine(
    $priceFilling,
    $currentModuleWidth,
    (priceFilling, currentModuleWidth) => {
      const { price = [] } = currentModuleWidth
        ? find(propEq('moduleWidth', currentModuleWidth.moduleWidth))(
            priceFilling,
          ) || {}
        : []

      return price
    },
  )

  // ******************************

  const $fillingMain = createStore([])
    .on(selectFillingMain, (state, { index, key, img }) => {
      return adjust(index, value => ({ ...value, selectKey: key, img }), state)
    })
    .reset(resetFilling)

  forward({
    to: $fillingMain,
    from: $currentFilling.map(({ main }) =>
      main.map(value => ({ size: value, selectKey: '' })),
    ),
  })

  initLocalStorage($fillingMain)

  const $fillingHeader = createStore([])
    .on(selectFillingHeader, (state, { index }) => {
      return adjust(
        index,
        value => ({ ...value, isActive: !value.isActive }),
        state,
      )
    })
    .reset(resetFilling)

  forward({
    to: $fillingHeader,
    from: $currentFilling.map(({ header }) =>
      header.map(value => ({ size: value, selectKey: '' })),
    ),
  })

  initLocalStorage($fillingHeader)

  const $fillingLedCode = $fillingHeader.map(list => {
    const size = list
      .filter(({ isActive }) => isActive)
      .map(({ size }) => size)
      .join('')
    return size && `LED${size}`
  })

  const $fillingCode = $fillingMain.map(list => {
    const code = list.map(({ selectKey }) => selectKey).join('')
    return code && `${code}`
  })

  // *******************

  const $commonPrice = combine(
    $fillingOptionsList,
    $fillingMain,
    (fillingOptionsList, fillingMain) => {
      const selectedKeys = fillingMain.filter(item => item.selectKey)

      const priceList = selectedKeys.map(({ selectKey }) =>
        find(propEq('name', selectKey))(fillingOptionsList || []),
      )

      return priceList.reduce((acc, cur) => acc + (cur ? cur.num : 0), 0)
    },
  )
  $commonPrice.reset(resetFilling)

  //* *********************

  const $ledPrice = combine(
    $fillingLedList,
    $fillingLedCode,
    (fillingLedList, fillingLedCode) => {
      const priceList =
        fillingLedCode &&
        find(propEq('ledCode', fillingLedCode))(fillingLedList)

      const { num = 0 } = priceList || {}

      return num
    },
  )
  $ledPrice.reset(resetFilling)

  //* ********************************************************validationStep

  const $isValidFillingStep = $fillingMain.map(fillingMain => {
    if (fillingMain.length === 0) return null
    return fillingMain.every(elem => elem.selectKey !== '')
  })

  return {
    $fillingMain,
    $fillingHeader,
    selectFillingMain,
    selectFillingHeader,

    $fillingLedCode,
    $fillingCode,

    $priceFilling,
    $priceLed,

    $fillingOptionsList,

    $commonPrice,
    $ledPrice,
    $isValidFillingStep,
    resetFilling,

    loadFillingData,

    loadFillingDataFetching,
  }
}
