import { request } from '@features/common'

import { mockApiRequest } from './mock'

// const getFillingData = ({ heightCurrent }) =>
//   request('GET', '', { body: { heightCurrent } })

const getFillingData = ({ heightCurrent }) =>
  request('GET', `/get-filling/${heightCurrent}`)

// const getFillingData = ({ heightCurrent }) =>
//   mockApiRequest(100, {
//     priceFilling,
//     priceLed,
//   })

export const fillingApi = {
  getFillingData,
}

const priceFilling = [
  {
    moduleWidth: 450,
    price: [
      { name: 'H1', num: 1248, img: '/images/H1.png' },
      { name: 'H2', num: 3495, img: '/images/H2.png' },
      { name: 'H3', num: 3183, img: '/images/H3.png' },
      { name: 'H4', num: 5052, img: '/images/H4.png' },
      { name: 'H5', num: 913, img: '/images/H5.png' },
      { name: 'H6', num: 1225, img: '/images/H6.png' },
      { name: 'H7', num: 3472, img: '/images/H7.png' },
      { name: 'H8', num: 4297, img: '/images/H8.png' },
      { name: 'H9', num: 5341, img: '/images/H9.png' },
      { name: 'H10', num: 3472, img: '/images/H10.png' },
      { name: 'H11', num: 1537, img: '/images/H11.png' },
      { name: 'H12', num: 3784, img: '/images/H12.png' },
    ],
  },
  {
    moduleWidth: 500,
    price: [
      { name: 'H1', num: 1344, img: '/images/H1.png' },
      { name: 'H2', num: 3633, img: '/images/H2.png' },
      { name: 'H3', num: 3297, img: '/images/H3.png' },
      { name: 'H4', num: 5211, img: '/images/H4.png' },
      { name: 'H5', num: 945, img: '/images/H5.png' },
      { name: 'H6', num: 1281, img: '/images/H6.png' },
      { name: 'H7', num: 3570, img: '/images/H7.png' },
      { name: 'H8', num: 4416, img: '/images/H8.png' },
      { name: 'H9', num: 5484, img: '/images/H9.png' },
      { name: 'H10', num: 3570, img: '/images/H10.png' },
      { name: 'H11', num: 1617, img: '/images/H11.png' },
      { name: 'H12', num: 3906, img: '/images/H12.png' },
    ],
  },
  {
    moduleWidth: 550,
    price: [
      { name: 'H1', num: 1416, img: '/images/H1.png' },
      { name: 'H2', num: 3771, img: '/images/H2.png' },
      { name: 'H3', num: 3417, img: '/images/H3.png' },
      { name: 'H4', num: 5355, img: '/images/H4.png' },
      { name: 'H5', num: 995, img: '/images/H5.png' },
      { name: 'H6', num: 1349, img: '/images/H6.png' },
      { name: 'H7', num: 3704, img: '/images/H7.png' },
      { name: 'H8', num: 4550, img: '/images/H8.png' },
      { name: 'H9', num: 5642, img: '/images/H9.png' },
      { name: 'H10', num: 3704, img: '/images/H10.png' },
      { name: 'H11', num: 1703, img: '/images/H11.png' },
      { name: 'H12', num: 4058, img: '/images/H12.png' },
    ],
  },
]
const priceLed = [
  {
    moduleWidth: 450,
    price: [
      { ledCode: 'LED1', num: 2555 },
      { ledCode: 'LED11', num: 5110 },
      { ledCode: 'LED111', num: 7665 },
      { ledCode: 'LED1111', num: 10220 },
      { ledCode: 'LED11111', num: 12775 },
      { ledCode: 'LED111111', num: 15330 },

      // { ledCode: 'LED11', num: 3630 },
      // { ledCode: 'LED12', num: 4608 },
      // { ledCode: 'LED121', num: 5660 },
      // { ledCode: 'LED122', num: 6590 },
      // { ledCode: 'LED221', num: 6590 },
      // { ledCode: 'LED2', num: 3605 },
      // { ledCode: 'LED21', num: 4608 },
      // { ledCode: 'LED22', num: 5635 },
      // { ledCode: 'LED222', num: 9215 },
    ],
  },
  {
    moduleWidth: 500,
    price: [
      { ledCode: 'LED1', num: 2565 },
      { ledCode: 'LED11', num: 5130 },
      { ledCode: 'LED111', num: 7695 },
      { ledCode: 'LED1111', num: 10260 },
      { ledCode: 'LED11111', num: 12825 },
      { ledCode: 'LED111111', num: 15390 },

      // { ledCode: 'LED11', num: 3655 },
      // { ledCode: 'LED12', num: 4658 },
      // { ledCode: 'LED121', num: 5735 },
      // { ledCode: 'LED122', num: 6738 },
      // { ledCode: 'LED221', num: 6738 },
      // { ledCode: 'LED2', num: 3630 },
      // { ledCode: 'LED21', num: 4658 },
      // { ledCode: 'LED22', num: 5710 },
      // { ledCode: 'LED222', num: 9288 },
    ],
  },
  {
    moduleWidth: 550,
    price: [
      { ledCode: 'LED1', num: 2585 },
      { ledCode: 'LED11', num: 5170 },
      { ledCode: 'LED111', num: 7755 },
      { ledCode: 'LED1111', num: 10340 },
      { ledCode: 'LED11111', num: 12925 },
      { ledCode: 'LED111111', num: 15510 },

      // { ledCode: 'LED11', num: 3680 },
      // { ledCode: 'LED12', num: 4705 },
      // { ledCode: 'LED121', num: 6718 },
      // { ledCode: 'LED122', num: 7673 },
      // { ledCode: 'LED221', num: 7673 },
      // { ledCode: 'LED2', num: 3655 },
      // { ledCode: 'LED21', num: 4705 },
      // { ledCode: 'LED22', num: 6693 },
      // { ledCode: 'LED222', num: 11160 },
    ],
  },
]
