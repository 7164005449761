import { createEvent, createStore } from 'effector'

export const showModal = createEvent('open model')
export const hideModal = createEvent('close model')

export const $modalComponent = createStore({ comp: null, props: null })
  .on(showModal, (_, { comp, props }) => ({
    comp,
    props: { ...props, onClose: hideModal },
  }))
  .reset(hideModal)

export const $isOpenModal = $modalComponent.map(({ Comp }) => Boolean(Comp))
