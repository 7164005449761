import styled from 'styled-components'

export const Title = styled.div`
  position: relative;
  display: block;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: ${p =>
    p.secondary ? 'var(--color-fontSecondary)' : 'var(--color-fontPrimary)'};
  font-size: ${({ size }) => size || '30px'};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.02) 0%,
      rgba(0, 0, 0, 0.13) 50%,
      rgba(0, 0, 0, 0.77) 93%,
      rgba(0, 0, 0, 0.83) 96%,
      rgba(0, 0, 0, 0.81) 100%
    );
  }
`
