import React from 'react'
import { omit } from 'ramda'
import { createStore, createEvent, is, clearNode } from 'effector'
import { useStoreMap } from 'effector-react'

import { LoggerContext } from './logger'

const manager = React.createContext()

export function Scope({ value, entries, children }) {
  value.addEntries(entries)

  const child = typeof children === 'function' ? children() : children

  return <manager.Provider value={value}>{child}</manager.Provider>
}

export function createScope() {
  const scope = createStore({
    state: {},
    initialized: false,
  })
  console.log('Scope store', scope)
  const addEntries = createEvent()
  scope.on(addEntries, (scope, entriesFabric) => {
    if (scope.initialized) return scope
    return {
      initialized: true,
      state: entriesFabric(),
    }
  })
  return { scope, addEntries }
}

let destroy = null
export function readScope(key, defaults, props) {
  const { scope } = React.useContext(manager)
  const log = React.useContext(LoggerContext)

  let newShapes = null
  const result = useStoreMap({
    store: scope,
    keys: [key],
    fn({ state }, [key]) {
      if (key in state) return state[key]
      destroy = []
      let result
      try {
        result = defaults(state, props)
        newShapes = destroy
      } finally {
        destroy = null
      }

      // Dev Tools
      loggerShape(result, key, log)

      state[key] = result

      return result
    },
  })

  /* eslint-disable-next-line */
  const shapes = React.useMemo(() => newShapes, [key])
  useClearOnUnmount(shapes, defaults, scope, key)

  return result
}

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

function clearShape(shape) {
  if (shape !== new Object(shape)) return
  if (is.unit(shape)) return clearNode(shape)
  if (Array.isArray(shape)) return shape.forEach(clearShape)
  for (const key in shape) clearShape(shape[key])
}

function logger(unit, scopeName, log) {
  const keyLogger = `${scopeName} -> ${unit.shortName} [${
    unit.kind === 'store' ? 'store' : 'event'
  }]`
  log.on(unit, (state, value) => ({ ...state, [keyLogger]: value }))
}

function loggerShape(shape, scopeName, log) {
  if (shape !== new Object(shape)) return
  if (is.unit(shape)) return logger(shape, scopeName, log)
  if (Array.isArray(shape)) return shape.forEach(clearShape)
  for (const key in shape) loggerShape(shape[key], scopeName, log)
}

export function useClearOnUnmount(shape, isUnmount, scope, key) {
  if (destroy) {
    destroy.push(shape)
  } else {
    useIsomorphicLayoutEffect(
      () => () => {
        console.log('Clear shape', shape, scope, key)
        if (isUnmount) {
          scope.stateRef.current.state = omit(
            [key],
            scope.stateRef.current.state,
          )
          clearShape(shape)
        }
      },
      [shape],
    )
  }
  return shape
}
