/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { BlobProvider } from '@react-pdf/renderer'

export const PdfDocument = ({
  title,
  document,
  isReady,
  renderLoading,
  renderDone,
  render = () => {},
}) => {
  const [readyInner, setReady] = useState(false)

  useEffect(() => {
    if (isReady) {
      setTimeout(() => {
        setReady(true)
      }, 300)
    }
  }, [isReady])

  if (!readyInner) {
    return render()
  }
  return (
    <BlobProvider document={document}>
      {({ url, loading, error }) => {
        if (loading) {
          return renderLoading()
        }
        if (!loading && url) {
          return renderDone({ title, url })
        }
        if (error) {
          console.error(error)
          return <p>An error occurred</p>
        }
        return render()
      }}
    </BlobProvider>
  )
}
