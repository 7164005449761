import {
  createEvent,
  createStore,
  combine,
  forward,
  createEffect,
} from 'effector'
import { adjust, find, propEq } from 'ramda'
import { createFetching } from '@lib/fetching'
import { doorApi } from '@api/door'

import { initLocalStorage } from '@lib/effector-helpers'

export const initiateDoorModel = ({ carcass }) => {
  const {
    $currentModuleWidth,
    $optionCarcassCurrent,
    $currentFilling,
    $heightCurrent,
    $materialSideWallsGroupCurrent,
  } = carcass

  // Events
  const frameDoorsHandleTypeChanged = createEvent()
  const frameDoorsChanged = createEvent()
  const selectDoorMain = createEvent()
  const resetDoor = createEvent()

  // Effects
  const loadDoorData = createEffect({
    handler: ({ heightCurrent }) => doorApi.getDoorData({ heightCurrent }),
  })
  const loadDoorDataFetching = createFetching(loadDoorData)

  // Stores
  const $priseDoorData = createStore([])
  const $frameDoorsList = createStore([])
  const $doorTypes = createStore([])
  const $frameDoorsHandleTypeList = createStore([])

  $priseDoorData.on(loadDoorData.done, (_, { result }) => result.priceDoor)
  $frameDoorsList.on(loadDoorData.done, (_, { result }) =>
    result.frameDoorsList.map(({ value }) => ({ value, label: value })),
  )
  $frameDoorsHandleTypeList
    .on(loadDoorData.done, (_, { result }) =>
      result.frameDoorsHandleTypeList.map(({ value }) => ({
        value,
        label: value,
      })),
    )
    .reset(resetDoor)

  $doorTypes.on(loadDoorData.done, (_, { result }) => result.doorTypes)

  const $frameDoorsHandleTypeCurrent = createStore('')
    .on(frameDoorsHandleTypeChanged, (_, { value }) => value)
    .reset(resetDoor)

  forward({
    to: $frameDoorsHandleTypeCurrent,
    from: $frameDoorsHandleTypeList.map(
      item => item && item.length !== 0 && item[0].value,
    ),
  })

  $frameDoorsHandleTypeList.watch(c =>
    console.log('frameDoorsHandleTypeList', c),
  )

  initLocalStorage($frameDoorsHandleTypeCurrent)

  const $doorMain = createStore([])
    .on(selectDoorMain, (state, { index, key, img }) => {
      return adjust(
        index,
        value => ({ ...value, selectKey: key, img: img }),
        state,
      )
    })
    .reset(resetDoor)

  forward({
    to: $doorMain,
    from: $currentFilling.map(({ main }) =>
      main.map(value => ({ size: value, selectKey: '' })),
    ),
  })

  $heightCurrent.updates.watch(value => {
    resetDoor()
    loadDoorData({ heightCurrent: value })
  })

  initLocalStorage($doorMain)

  const $frameDoorCurrent = createStore('')
    .on(frameDoorsChanged, (state, { value }) => value)
    .reset($doorMain.updates)
    .reset(resetDoor)

  initLocalStorage($frameDoorCurrent)

  const $doorCode = $doorMain.map(list => {
    const doorCode = list
      .map(({ selectKey }) => selectKey)
      .map(item => item.substring(0, item.length - 1))

    const count = arr =>
      arr.reduce((a, b) => ({ ...a, [b]: (a[b] || 0) + 1 }), {})
    const res = count(doorCode)
    const { DP = '', DR = '' } = res

    return res
  })

  //* ******************************************priceDoor

  const $priceDoor = combine(
    $priseDoorData,
    $currentModuleWidth,
    $frameDoorsHandleTypeCurrent,
    $frameDoorCurrent,
    $optionCarcassCurrent,
    $doorMain,
    $materialSideWallsGroupCurrent,
    (
      data,
      currentModuleWidth,
      frameDoorsHandleTypeCurrent,
      frameDoorCurrent,
      optionCarcassCurrent,
      doorMain,
      materialSideWallsGroupCurrent,
    ) => {
      let frameDoorsHandleTypeListDefault = null
      const frameDoorsHandleTypeList = $frameDoorsHandleTypeList.getState()
      if (frameDoorsHandleTypeList && frameDoorsHandleTypeList.length !== 0) {
        frameDoorsHandleTypeListDefault = frameDoorsHandleTypeList[0].value
      }
      const listDoorsCode = doorMain.filter(({ selectKey }) => selectKey)

      const { moduleWidth } = currentModuleWidth || {}

      const { option = [] } =
        find(propEq('moduleWidth', moduleWidth))(data) || {}

      const result = listDoorsCode.map(({ selectKey }) => {
        const normalizedSelectKey = selectKey.substring(0, selectKey.length - 1)

        const { optionPrice: listOptionPrice } =
          find(propEq('optionDoor', normalizedSelectKey))(option) || {}

        const { price: listPrice } =
          find(
            propEq(
              'doorHandle',
              frameDoorsHandleTypeCurrent || frameDoorsHandleTypeListDefault,
            ),
          )(listOptionPrice || []) || {}

        if (normalizedSelectKey === 'DP') {

          if (optionCarcassCurrent === 'ЛДСП') {

            const { option: optionCarcassName = 'ЛДСП' } = materialSideWallsGroupCurrent || {}

            const { num: priceOptionCarcassCurrent } =
              find(propEq('carcassName', optionCarcassName))(listPrice || []) ||
              {}
            return priceOptionCarcassCurrent
          } else {
            const { num: priceOptionCarcassCurrent } =
              find(propEq('carcassName', optionCarcassCurrent))(
                listPrice || [],
              ) || {}
            return priceOptionCarcassCurrent
          }
        }

        const { num = 0 } =
          find(propEq('carcassName', frameDoorCurrent))(listPrice || []) || {}
        return num
      })

      const price = result.reduce((acc, cur) => acc + cur, 0)

      console.log('price doors', result)
      console.log('price', price)

      return price
    },
  )
  $priceDoor.reset(resetDoor)

  $priceDoor.watch(el => console.log('PriceDoor stores', el))

  //* ********************************************************validationStep

  const $isDR = combine($doorMain, doorMain => {
    const substring = 'DR'
    const isDR = doorMain.some(elem => elem.selectKey.includes(substring))
    return isDR
  })

  const $isDP = combine($doorMain, doorMain => {
    const substring = 'DP'
    const isDP = doorMain.some(elem => elem.selectKey.includes(substring))
    return isDP
  })

  const $isValidDoorStep = combine(
    $doorMain,
    $frameDoorsHandleTypeCurrent,
    $frameDoorCurrent,
    $optionCarcassCurrent,
    $materialSideWallsGroupCurrent,
    $isDR,
    $isDP,
    (
      doorMain,
      frameDoorsHandleTypeCurrent,
      frameDoorCurrent,
      optionCarcassCurrent,
      materialSideWallsGroupCurrent,
      isDR,
      isDP,
    ) => {
      const isValidDoorMain = doorMain.every(elem => elem.selectKey !== '')

      if (isDR && isDP) {
        return (
          isValidDoorMain &&
          frameDoorsHandleTypeCurrent &&
          frameDoorCurrent &&
          (optionCarcassCurrent || materialSideWallsGroupCurrent)
        )
      }
      if (!isDP && isDR) {
        return (
          isValidDoorMain && frameDoorsHandleTypeCurrent && frameDoorCurrent
        )
      }
      if (!isDR && isDP) {
        if (optionCarcassCurrent !== 'ЛДСП') {
          return (
            isValidDoorMain &&
            frameDoorsHandleTypeCurrent &&
            !frameDoorCurrent &&
            optionCarcassCurrent
          )
        } else {
          return (
            isValidDoorMain &&
            frameDoorsHandleTypeCurrent &&
            !frameDoorCurrent &&
            (optionCarcassCurrent && materialSideWallsGroupCurrent)
          )
        }
      }
    },
  )

  return {
    frameDoorsChanged,
    $frameDoorsList,
    $frameDoorCurrent,
    $frameDoorsHandleTypeList,
    $frameDoorsHandleTypeCurrent,
    frameDoorsHandleTypeChanged,
    selectDoorMain,
    $doorMain,
    $doorCode,
    $isValidDoorStep,
    $isDR,
    $isDP,
    $priceDoor,
    resetDoor,
    loadDoorData,
    loadDoorDataFetching,
    $doorTypes,
  }
}
