/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { icons } from './sprites'

export const Icon = ({ name = '', style = {}, className = {} }) => (
  <svg
    className={className}
    style={style}
    viewBox={icons[name].viewBox}
    aria-hidden="true"
    focusable="false"
    data-icon="bookmark"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    {icons[name].path()}
  </svg>
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  style: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
}

Icon.defaultProps = {
  width: undefined,
  height: undefined,
  fill: undefined,
  style: undefined,
  viewBox: undefined,
  className: undefined,
}

export const Arrow = () => {
  return (
    <ArrowBox>
      <div
        aria-hidden="true"
        className="Select__indicator Select__dropdown-indicator css-tlfecz-indicatorContainer"
      >
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          className="css-tj5bde-Svg"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
        </svg>
      </div>
    </ArrowBox>
  )
}

export const ArrowBox = styled.div`
  position: absolute;
  top: 1px;
  right: 0;
`
