import React from 'react'
import { HeaderEmpty } from '@features/common'
import { Row, Col } from '@lib/styled-components-layout'

import { CenterContentTemplate, Container } from '@ui/templates'

import { Card, Button, ButtonDark } from '@ui/atoms'
import { logoutPressed, cancelPressed } from '@features/join'
import styled from 'styled-components'

export const LogoutPage = () => {
  return (
    <CenterContentTemplate isFull header={<HeaderEmpty />}>
      <Container justify="center" align="center">
        <Col align="stretch" width="30rem">
          <Card>
            <Row padding="0 0 2rem 0" style={{fontSize: '24px'}}>Хотите выйти?</Row>
            <Row gap="1rem" justify='space-between'>
              <ButtonDark style={{width: '200px', margin: 0}} grow onClick={logoutPressed}>
                Выйти
              </ButtonDark>
              <Button style={{width: '200px', marginTop: 0}} onClick={cancelPressed}>Отмена</Button>
            </Row>
          </Card>
        </Col>
      </Container>
    </CenterContentTemplate>
  )
}


