import React from 'react'

export const FooterContent = () => (
  <div>
    &copy; 2018–{new Date().getFullYear()}{' '}
    <a
      href="https://github.com/orgs/howtocards/people"
      rel="noopener noreferrer"
      target="_blank"
    >
      HowToCards Team
    </a>
  </div>
)
