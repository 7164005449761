import { useEffect } from 'react'
import { loadSession } from '../model/session.events'

export const AccountLoader = ({ children }) => {
  useEffect(() => {
    loadSession()
  }, [])

  return children
}
