import { createStore } from 'effector'
import { useClearOnUnmount } from '@lib/effector-helpers/scope'

export const $baseUri = createStore('/api')

export const initiateCommonModel = () => {
  const $time = createStore(null)

  return useClearOnUnmount({
    common: {
      $time,
    },
  })
}
