import React from 'react'

import { Row, Col } from '@lib/styled-components-layout'
import { initiateAdminPageModel } from './model'

export const AdminPage = () => {
  const { isDone } = initiateAdminPageModel()

  return <Col width="100%">{isDone ? 'nonDone' : 'done'}</Col>
}
