import React from 'react'
import { SuccessStep } from '@features/order'
import { Carcass } from '@features/carcass'
import { Filling } from '@features/filling'
import { Door } from '@features/door'
import { Checkout } from '@features/checkout'

import { HomePage } from './page'

export const homeRoutes = () => [
  {
    path: '/',
    component: HomePage,
    routes: [
      {
        path: '/step1',
        exact: true,
        component: Carcass,
      },
      {
        path: '/step2',
        exact: true,
        component: Filling,
      },
      {
        path: '/step3',
        exact: true,
        component: Door,
      },
      {
        path: '/step4',
        exact: true,
        component: Checkout,
      },
      {
        path: '/step5',
        exact: true,
        component: SuccessStep,
      },
    ],
  },
]
