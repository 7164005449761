import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Modal } from '@ui/organisms/modal/modal'
import { useStore } from 'effector-react'
import { readScope } from '../../../lib/effector-helpers/scope'
import { Button } from '@ui'

export const DoorModal = ({ onClose, index }) => {
  const { selectDoorMain, $doorTypes } = readScope('door')
  const doorTypes = useStore($doorTypes)
  return (
    <Modal onClose={onClose} title="Выбирите варианты материалов дверей">
      <DoorModalBox>
        {doorTypes.map(({ name, img }) => (
          <Item key={name}>
            <img src={img} alt="" />
            <Button
              onClick={useCallback(() => {
                selectDoorMain({ index, key: name, img: img })
                onClose()
              }, [name])}
            >
              {name}
            </Button>
          </Item>
        ))}
      </DoorModalBox>
    </Modal>
  )
}

DoorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

const DoorModalBox = styled.ul`
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const Item = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
`
