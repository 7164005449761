import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  :root {
    ${p => p.theme.palette};
  }
  
    @font-face {
        font-family: 'Lato';
        src: url('/fonts/Lato-Regular.eot');
        src: local('/fonts/Lato Regular'), local('Lato-Regular'),
            url('/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
            url('/fonts/Lato-Regular.woff') format('woff'),
            url('/fonts/Lato-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lato';
        src: url('/fonts/Lato-Black.eot');
        src: local('/fonts/Lato Black'), local('Lato-Black'),
            url('/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
            url('/fonts/Lato-Black.woff') format('woff'),
            url('/fonts/Lato-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Lato';
        src: url('/fonts/Lato-Medium.eot');
        src: local('/fonts/Lato Medium'), local('Lato-Medium'),
            url('/fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
            url('/fonts/Lato-Medium.woff') format('woff'),
            url('/fonts/Lato-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lato';
        src: url('/fonts/Lato-Bold.eot');
        src: local('/fonts/Lato Bold'), local('Lato-Bold'),
            url('/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
            url('/fonts/Lato-Bold.woff') format('woff'),
            url('/fonts/Lato-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }

  body {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: normal;
    background-color: var(--color-bgPrimary);
    color: var(--color-fontPrimary);
    -webkit-font-smoothing: antialiased;
    ${p =>
      p.isOpenModal &&
      css`
        overflow: hidden;
      `}
  }

  h2 {
    font-weight: 400;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  
  form {
    width: 100%;
`
