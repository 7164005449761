import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

export const Link = styled(RouterLink)`
  text-decoration: ${p => (p.underLine ? 'underline' : 'none')};

  &:hover {
    text-decoration: ${p => (p.underLine || p.none ? 'none' : 'underline')};
  }

  text-transform: ${p => (p.uppercase ? 'uppercase' : 'none')};

  color: ${p =>
    p.secondary ? `var(--color-fontSecondary)` : `var(--color-link)`};
`
