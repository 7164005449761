import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'

const AnimFeTurbulence = animated('feTurbulence')
const AnimFeDisplacementMap = animated('feDisplacementMap')

export const Logo = () => {
  const [open, toggle] = useState(false)
  const { freq, scale, transform, opacity } = useSpring({
    onStart: () => toggle(true),
    reset: open,
    from: {
      scale: 10,
      opacity: 0,
      transform: 'scale(0.9)',
      freq: '0.0070, 0.0',
    },
    to: { scale: 150, opacity: 1, transform: 'scale(1)', freq: '0.0, 0.0' },
    config: { duration: 1000 },
  })

  return (
    <animated.svg
      onClick={() => toggle(prev => !prev)}
      width="180px"
      height="80px"
      style={{ transform, opacity }}
      viewBox="0 0 179.23 25.06"
    >
      <defs>
        <filter id="water">
          <AnimFeTurbulence
            type="fractalNoise"
            baseFrequency={freq}
            numOctaves="1.5"
            result="TURB"
            seed="8"
          />
          <AnimFeDisplacementMap
            xChannelSelector="R"
            yChannelSelector="G"
            in="SourceGraphic"
            in2="TURB"
            result="DISP"
            scale={scale}
          />
        </filter>
      </defs>

      <g
        filter="url(#water)"
        clipPath="url(#clip0)"
        id="g16"
        transform="translate(0,-0.0692139)"
      >
        <animated.path
          d="m 176.228,0.417816 c -1.676,0 -3.003,1.323124 -3.003,2.994434 0,1.6713 1.327,2.99442 3.003,2.99442 1.676,0 3.004,-1.32312 3.004,-2.99442 0,-1.67131 -1.328,-2.994434 -3.004,-2.994434 z m 0,5.501394 c -1.397,0 -2.514,-1.11421 -2.514,-2.50696 0,-1.39276 1.117,-2.506969 2.514,-2.506969 1.397,0 2.515,1.114209 2.515,2.506969 0,1.39275 -1.118,2.50696 -2.515,2.50696 z"
          id="path2"
          style={{ fill: '#ffffff' }}
        />
        <animated.path
          d="m 177.625,2.71586 c 0,-0.62674 -0.559,-1.04456 -1.187,-1.04456 h -1.397 V 5.15319 H 175.6 V 3.76043 h 0.558 l 0.908,1.39276 h 0.629 l -0.908,-1.39276 c 0.14,0 0.838,-0.27855 0.838,-1.04457 z M 175.53,3.3426 V 2.08912 h 0.908 c 0.279,0 0.628,0.20892 0.628,0.62674 0,0.34819 -0.349,0.55711 -0.628,0.55711 h -0.908 z"
          id="path4"
          style={{ fill: '#ffffff' }}
        />
        <animated.path
          d="M 103.865,0.417816 V 24.6518 h 5.239 21.094 v -4.3872 -0.4178 h -20.256 v -4.8747 h 20.256 V 14.7632 10.376 10.1671 H 109.942 V 5.22283 h 20.256 V 4.73536 0.417816 h -21.094 z"
          id="path6"
          style={{ fill: '#ffffff' }}
        />
        <animated.path
          d="M 35.553,0.417816 V 24.6518 h 5.2386 21.0943 V 20.2646 19.8468 H 41.6997 V 14.9721 H 61.8859 V 14.7632 10.376 10.1671 H 41.6997 V 5.22283 H 61.8859 V 4.73536 0.417816 H 40.7916 Z"
          id="path8"
          style={{ fill: '#ffffff' }}
        />
        <animated.path
          d="M 160.931,0.417816 V 20.2646 L 144.587,0.417816 h -9.569 V 24.6518 h 6.146 V 4.73536 l 16.345,19.91644 h 9.569 V 24.5822 0.417816 h -3.981 z"
          id="path10"
          style={{ fill: '#ffffff' }}
        />
        <animated.path
          d="M 98.9756,10.0971 H 98.2771 82.9104 l -3.9814,4.805 h 13.8999 c -0.1397,2.3677 -0.6287,3.6908 -1.4668,4.3175 -0.6287,0.4875 -2.0257,0.975 -7.404,1.0446 h 0.6286 c -0.5588,0 -1.1176,0 -1.7462,0 -6.2864,0 -7.823,-0.4875 -8.5215,-1.0446 -1.0478,-0.766 -1.5367,-2.7855 -1.5367,-6.6156 0,-3.89969 0.4889,-5.91919 1.5367,-6.6852 0.6985,-0.48747 2.2351,-1.04457 8.4517,-1.04457 0.0698,0 0.0698,0 0.1397,0 6.1466,0 7.6833,0.5571 8.3818,1.04457 0.3492,0.27855 0.6286,0.62674 0.8382,1.18384 h 6.4959 C 98.5565,6.19735 98.4168,5.50097 98.2771,4.87423 97.858,2.994 97.2992,2.15835 96.9499,1.8798 96.1118,1.18342 93.1781,0.0692139 82.7707,0.0692139 72.3632,0.0692139 69.4296,1.18342 68.5914,1.8798 67.8929,2.50654 66.6356,4.66531 66.6356,12.604 c 0,7.9387 1.2573,10.1672 2.0256,10.7939 0.7684,0.6267 3.702,1.7409 14.1095,1.7409 10.4074,0 13.2712,-1.1142 14.1094,-1.7409 0.4191,-0.3482 0.9779,-1.1838 1.397,-3.0641 v -0.0696 c 0.3492,-1.6017 0.6286,-4.039 0.6286,-7.5905 0.1397,-1.0446 0.0699,-1.8803 0.0699,-2.5766 z"
          id="path12"
          style={{ fill: '#ffffff' }}
        />
        <animated.path
          d="M 0,5.22283 H 21.3038 L 0,19.7075 v 4.9443 h 30.7334 v -4.805 H 9.15017 L 30.7334,5.29247 V 0.417816 H 0 Z"
          id="path14"
          style={{ fill: '#ffffff' }}
        />
      </g>

      <defs id="defs21">
        <clipPath id="clip0">
          <animated.rect
            width="180"
            height="25"
            id="rect18"
            x="0"
            y="0"
            style={{ fill: '#ffffff' }}
          />
        </clipPath>
      </defs>
    </animated.svg>
  )
}
