import React from 'react'
import { readScope } from '@lib/effector-helpers'
import { useStore } from 'effector-react'

import PropTypes from 'prop-types'

import { Col } from '@lib/styled-components-layout'

import styled from 'styled-components'
import { Text } from '@ui'

export const OrderComment = () => {
  const {
    $optionCarcassCurrent,
    $materialCarcassCurrent,
    $materialSideWallsCurrent,
  } = readScope('carcass')

  const { $frameDoorCurrent } = readScope('door')
  const { $checkoutInfo } = readScope('checkout')

  const option = useStore($optionCarcassCurrent)
  const material = useStore($materialCarcassCurrent)
  const side = useStore($materialSideWallsCurrent)

  const frameDoor = useStore($frameDoorCurrent)
  const {
    firstName,
    lastName,
    phone,
    email,
    dealerName,
    address,
    comment,
  } = useStore($checkoutInfo)

  return (
    <Col>
      <OrderCommentBox>
        <Text light>Материалы и декоры</Text>
        <Text>{option}</Text>
        <Text>{material}</Text>
        <Text>{side}</Text>
        <Text>{frameDoor}</Text>
      </OrderCommentBox>
      <OrderCommentBox>
        <Text light>Информация о заказчике</Text>
        <Text>
          {firstName} {lastName}
        </Text>
        <Text>{phone}</Text>
        <Text>{email}</Text>
      </OrderCommentBox>
      <OrderCommentBox>
        <Text light>Информация о дилере</Text>
        <Text>{dealerName}</Text>
        <Text>{address}</Text>
      </OrderCommentBox>

      <Text light>Комментарий</Text>
      <Text style={{overflow: 'hidden'}}>{comment}</Text>
    </Col>
  )
}

const OrderCommentBox = styled.div`
  margin-bottom: 20px;
`

OrderComment.propTypes = {
  materialCarcass: PropTypes.string,
  materialSide: PropTypes.string,
  materialFrameDoor: PropTypes.string,
  nameCustomer: PropTypes.string,
  phoneCustomer: PropTypes.string,
  emailCustomer: PropTypes.string,
  nameSeller: PropTypes.string,
  pointSale: PropTypes.string,
  comment: PropTypes.string,
}

OrderComment.defaultProps = {
  materialCarcass: null,
  materialSide: null,
  materialFrameDoor: null,
  nameCustomer: null,
  phoneCustomer: null,
  emailCustomer: null,
  nameSeller: null,
  pointSale: null,
  comment: null,
}
