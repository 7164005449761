import React from 'react'
import { createStore, sample } from 'effector'

export const LoggerContext = React.createContext()

export function WithLogging({ name = 'state', children }) {
  const logRef = React.useRef()
  const logHistory = React.useRef()

  if (logRef.current === undefined) {
    logRef.current = createStore({})
    logHistory.current = createStore([
      logRef.current.getState(),
      logRef.current.getState(),
    ])

    logHistory.current.on(sample(logRef.current), ([lastState], newState) => [
      newState,
      lastState,
    ])

    sample(logRef.current).watch(shape => {
      console.groupCollapsed(`[${name}] effector update`)
      console.log(`%cUpdates:`, 'font-weight: 700')
      Object.keys(shape).forEach(key => {
        const value = shape[key]
        if (logHistory.current.getState()[1][key] !== value) {
          console.log(`%c[${key}]`, 'font-weight: 600; color: gray', shape[key])
        }
        // else {
        //   console.log(`[${key}]`, shape[key])
        // }
      })
      console.log(`%cAll:`, 'font-weight: 700', shape)
      console.groupEnd()
    })
  }

  return (
    <LoggerContext.Provider value={logRef.current}>
      {children}
    </LoggerContext.Provider>
  )
}
