import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Icon } from '../../atoms/icon'

const IconClose = styled(Icon)`
  transition: all 0.25s ease;

  &:hover {
    opacity: 0.7;
  }
`

const Button = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 23px;
  height: 23px;
  border: 0;
  border-radius: 50%;
  transition: all 0.25s ease;
  cursor: pointer;
`

export const ModalClose = ({ onClick }) => (
  <Button type="button" onClick={onClick}>
    <IconClose name="circleClose" />
  </Button>
)

ModalClose.propTypes = {
  onClick: PropTypes.func.isRequired,
}
