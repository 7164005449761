import React, { useRef } from 'react'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'
import PropTypes from 'prop-types'

import { H3 } from '../../atoms/heading'
import { ModalClose } from './modal-close'

export const Modal = ({ children, title, onClose }) => {
  const ref = useRef(null)
  useOnClickOutside(ref, onClose)

  return (
    <ModalOverlay>
      <ModalItem ref={ref}>
        {title && (
          <TitleText narrow secondary>
            {title}
          </TitleText>
        )}
        {children}
        <ModalClose onClick={onClose} />
      </ModalItem>
    </ModalOverlay>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
}

Modal.defaultProps = {
  title: undefined,
}

const ModalOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
`

const ModalItem = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  border-radius: 3px;
  background-color: #ffffff;
  @media (max-width: 1440px) {
    width: 90%;
  }
`

const TitleText = styled(H3)`
  color: #3c3d3e;
  font-size: 24px;
  line-height: 29px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
`
