import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { Row, Col } from '@lib/styled-components-layout'
import { history } from '@lib/routing'
import { PdfDocument } from '@lib/pdf-document'
import { readScope } from '@lib/effector-helpers'

import { useStore } from 'effector-react'
import { OrderPrint } from './order-print'
import { Button, ButtonDark, ButtonDarkPDF, StepTitle } from '@ui'

export const SuccessStep = () => {
  const { arrayEvents } = readScope('success-step')
  const {
    setDataString,
    setDataStringLoading,
    $dataStringOrderPrint,
    resetDataString,
  } = readScope('order-print')

  const isDone = useStore(setDataStringLoading.isDone)

  useEffect(() => {
    setTimeout(() => {
      setDataString([
        {
          name: 'canvasImgComment',
          elemId: 'canvasImgComment',
        },
      ])
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { canvasImgComment = '' } = useStore($dataStringOrderPrint) || {}

  const data = { canvasImgComment }

  const dateNumber = new Date()
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  const dateOrder = dateNumber.toLocaleString('ru', options)

  return (
    <Col>
      <StepTitle titleStep="Спасибо! Ваш заказ успешно сформирован" />
      <StepBtnBox>
        <Row justify="space-between" align="center">
          <Button
            onClick={() => history.push('/step4')}
          >
            Назад
          </Button>

          <Row gap="15px" align="center" width="64%" justify="space-between">
            {isDone ? (
              <PdfDocument
                title="Hello"
                isReady={isDone}
                document={<OrderPrint data={data} dateOrder={dateOrder} />}
                renderDone={({ url }) => (
                  <ButtonDarkPDF
                    as="a"
                    href={url}
                    download={`Order_${dateOrder}.pdf`}
                  >
                    Загрузить PDF
                  </ButtonDarkPDF>
                )}
                renderLoading={() => <div>Cоздание PDF...</div>}
                render={() => <div>Cоздание PDF...</div>}
              />
            ) : (
              <div>Cоздание PDF...</div>
            )}

            <ButtonDark
              onClick={useCallback(() => {
                arrayEvents()
                resetDataString()
                history.push('/step1')
              }, [arrayEvents, resetDataString])}
              style={{ maxWidth: '110px', width: '100%', margin: '0' }}
            >
              Новый заказ
            </ButtonDark>
          </Row>
        </Row>
      </StepBtnBox>
    </Col>
  )
}

export const StepBtnBox = styled.div`
  max-width: 400px;
  width: 100%;
  ${Button} {
    max-width: 110px;
    width: 100%; 
    margin: 0;
  }
`
