/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import ReactPhoneInput from 'react-phone-input-2'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import 'react-phone-input-2/dist/style.css'

import { InputCashier, inputNativeMixin } from '@ui'

export const InputPhone = ({
  label,
  description,
  isTooltip,
  events,
  $stores,
  placeholder,
  disabled,
  width,
}) => {
  const { value, isChanged, isFocus, error } = useStore($stores)
  const { onBlur, onFocus, name, setValue } = events

  return (
    <InputCashier
      label={label}
      tooltip={isTooltip && (isFocus && description)}
      description={!isTooltip && description}
      error={isChanged && error} // also can be change
      inputInner={
        <PhoneInput
          disableDropdown
          inputExtraProps={{
            name,
          }}
          defaultCountry="ua"
          onlyCountries={['ua']}
          value={value}
          onChange={setValue}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
        />
      }
    />
  )
}

InputPhone.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isFocus: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  description: PropTypes.string,
}

InputPhone.defaultProps = {
  tooltip: null,
  error: null,
  label: null,
  placeholder: null,
  description: null,
}

const ReactPhoneInputWrap = ({ className, ...props }) => (
  <ReactPhoneInput
    containerClass={`${className} react-tel-input `}
    {...props}
  />
)

const PhoneInput = styled(ReactPhoneInputWrap)`

  && {
 
    text-align: left;
    .form-control {
      border-radius: 0;
       border: 1px solid #a1a1a1;
      color: #3c3d3e;
      position: relative;
      // padding: 0.7rem;
      font-size: 14px;
      width: 100%;
      box-sizing: border-box;
      outline: none;
      box-shadow: none;
      background-color: white;
      transition: box-shadow 120ms, border-color 120ms;
      box-shadow: 0 0 0 1px 0 0 0 1px black;
    
      &:focus {
        box-shadow: 0 0 0 1px black;
        border-color: lightgrey;
      }
      ${p =>
      p.error &&
      css`
      box-shadow: 0 0 0 1px red;
      border-color: red;
    `}
    }
    
    .flag-dropdown,
    .flag-dropdown.open-dropdown > .selected-flag,
    .selected-flag {
      background-color: transparent;
      border: none;
      border-radius: 0;
    }

    .selected-flag {
      z-index: 0;
    }

    .flag-dropdown:hover > .selected-flag {
      background-color: transparent
    }

    > input {
      height: 40px;
      width: 100%;
      // ${inputNativeMixin};
      padding: 0.7rem 0.7rem 0.7rem 30px;
      // padding-left: 30px;
      // padding-right: 1rem;
    }
    > input:focus {
    box-shadow: none;
    border-color: transparent;
}

    > input.invalid-number,
    input.invalid-number:focus {
      border: none;
      background-color: var(--color-bgPrimary);
      border-color: transparent;
      box-shadow: 0 0 0 1px transparent
    }

    .country-list {
      width: 100%;
      background-color: var(--color-bgPrimary);
    }

    .country-list .country.highlight {
      background-color: #404040;
    }

    .country-list .country:hover {
      background-color: transparent;
    }
  }

`

