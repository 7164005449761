import React, { useCallback } from 'react'
import { useStore } from 'effector-react'

import { Row, Col } from '@lib/styled-components-layout'

import { showModal } from '@features/common'

import { readScope } from '@lib/effector-helpers'
import styled from 'styled-components'

import { history } from '@lib/routing'
import { FillingModal } from '../molecules'
import { Text, StepTitle, FillingImg, Header, ButtonDark, Button } from '@ui'

const showModalCarcass = index =>
  showModal({ comp: FillingModal, props: { index } })

export const Filling = () => {
  const { $materialCarcassCurrent, $currentModuleWidth } = readScope('carcass')
  const {
    $fillingMain,
    $fillingLedCode,
    $fillingCode,
    $fillingHeader,
    selectFillingHeader,
    $isValidFillingStep,
  } = readScope('filling')


  const fillingMainList = useStore($fillingMain)
  const fillingHeaderList = useStore($fillingHeader)
  const material = useStore($materialCarcassCurrent)

  const fillingLedCode = useStore($fillingLedCode)
  const fillingCode = useStore($fillingCode)

  const { moduleWidth = '' } = useStore($currentModuleWidth) || {}

  const isValidStep = useStore($isValidFillingStep)

  return (
    <Col width="100%" height="100%">
      <StepTitle title="Шаг 2." titleStep="Выбор наполнения и освещения" />

      <Row
        justify="space-between"
        padding="0 0 15px"
        gap="25px"
        style={{ borderBottom: '1px solid #A1A1A1' }}
      >
        <Col basis="50%" gap="15px">
          <Text>Материал наполнения:</Text>
          <MaterialBox>{material}</MaterialBox>
          <Row gap="5px">
            <Text>Код комплекта наполнения:</Text>
            <Text bold>{`702.${moduleWidth}-${fillingCode} `}</Text>
          </Row>
        </Col>
        <Col basis="50%" justify="flex-end">
          <Row gap="5px" justify="flex-end">
            <Text>Код комплекта освещения:</Text>
            <Text bold>{`702.${moduleWidth}-${fillingLedCode}`}</Text>
          </Row>
        </Col>
      </Row>

      <Row
        width="100%"
        padding="40px 0 49px 0"
        gap="20px"
        grow="1"
        align="center"
        justify="center"
        style={{ height: '100%' }}
      >
        <Col
          style={{
            height: 'calc(100% - 80px)',
            justifyContent: 'space-between',
          }}
        >
          <Text>Вариант освещения:</Text>
          <Text>Вариант наполнения:</Text>
        </Col>

        <Col padding="20px 20px 20px">
          <FillingImg
            listMain={fillingMainList}
            header={
              <Header
                listHeader={fillingHeaderList}
                onClick={useCallback(index => selectFillingHeader({ index }), [
                  selectFillingHeader,
                ])}
              />
            }
            onClick={showModalCarcass}
          />
        </Col>
      </Row>

      <Row justify="space-between" align="center">
        <Button
          style={{ maxWidth: '110px', width: '100%', margin: '0' }}
          onClick={() => history.push('/step1')}
        >
          Назад
        </Button>
        <ButtonDark
          style={{ maxWidth: '110px', width: '100%', margin: '0' }}
          onClick={() => history.push('/step3')}
          disabled={!isValidStep}
        >
          Дальше
        </ButtonDark>
      </Row>
    </Col>
  )
}

export const MaterialBox = styled.div`
  cursor: not-allowed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #a1a1a1;
  height: 40px;
  padding-left: 15px;
`
