import React from 'react'
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'

// eslint-disable-next-line react/prop-types
export const OrderPrint = ({ data }) => (
  <Document>
    <Page style={styles.body}>
        <Image style={styles.image} src={data.canvasImgComment} />
    </Page>
  </Document>
)

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
  },
  image: {
    width: 595,
    marginVertical: 0,
    marginHorizontal: 0,
  },
})
