import { request } from '@features/common'

import { mockApiRequest } from './mock'

// const getDoorData = ({ heightCurrent }) =>
//   request('POST', '...', { body: { heightCurrent } })

const getDoorData = ({ heightCurrent }) =>
  request('GET', `/get-door/${heightCurrent}`)

// const getDoorData = () =>
//   mockApiRequest(100, {
//     priceDoor,
//     frameDoorsList,
//     frameDoorsHandleTypeList,
//     doorTypes,
//   })

export const doorApi = {
  getDoorData,
}

const priceDoor = [
  {
    moduleWidth: 450,
    option: [
      {
        optionDoor: 'DP',
        optionPrice: [
          {
            doorHandle: 'Р1 (1050мм)',
            price: [
              { carcassName: 'ЛДСП', num: 2726 },
              { carcassName: 'ЛДСП + ШПОН (масло)', num: 3533 },
              { carcassName: 'ЛДСП + ШПОН (лак)', num: 5102 },
            ],
          },
          {
            doorHandle: 'Р2 (200мм)',
            price: [
              { carcassName: 'ЛДСП', num: 3226 },
              { carcassName: 'ЛДСП + ШПОН (масло)', num: 4033 },
              { carcassName: 'ЛДСП + ШПОН (лак)', num: 5602 },
            ],
          },
        ],
      },
      {
        optionDoor: 'DR',
        optionPrice: [
          {
            doorHandle: 'Р1 (1050мм)',
            price: [
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО ТОНИРОВАННОЕ ЧЁРНОЕ',
                num: 9672,
              },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО LACOBEL® ЧЁРНОЕ',
                num: 9742,
              },
              { carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО', num: 8168 },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО ТОНИРОВАННОЕ СЕРОЕ',
                num: 9179,
              },
            ],
          },
          {
            doorHandle: 'Р2 (200мм)',
            price: [
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО ТОНИРОВАННОЕ ЧЁРНОЕ',
                num: 10172,
              },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО LACOBEL® ЧЁРНОЕ',
                num: 10242,
              },
              { carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО', num: 8668 },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО ТОНИРОВАННОЕ СЕРОЕ',
                num: 9679,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    moduleWidth: 500,
    option: [
      {
        optionDoor: 'DP',
        optionPrice: [
          {
            doorHandle: 'Р1 (1050мм)',
            price: [
              { carcassName: 'ЛДСП', num: 2852 },
              { carcassName: 'ЛДСП + ШПОН (масло)', num: 3733 },
              { carcassName: 'ЛДСП + ШПОН (лак)', num: 5470 },
            ],
          },
          {
            doorHandle: 'Р2 (200мм)',
            price: [
              { carcassName: 'ЛДСП', num: 3352 },
              { carcassName: 'ЛДСП + ШПОН (масло)', num: 4233 },
              { carcassName: 'ЛДСП + ШПОН (лак)', num: 5970 },
            ],
          },
        ],
      },
      {
        optionDoor: 'DR',
        optionPrice: [
          {
            doorHandle: 'Р1 (1050мм)',
            price: [
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО ТОНИРОВАННОЕ ЧЁРНОЕ',
                num: 10165,
              },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО LACOBEL® ЧЁРНОЕ',
                num: 10238,
              },
              { carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО', num: 8581 },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО ТОНИРОВАННОЕ СЕРОЕ',
                num: 9646,
              },
            ],
          },
          {
            doorHandle: 'Р2 (200мм)',
            price: [
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО ТОНИРОВАННОЕ ЧЁРНОЕ',
                num: 10665,
              },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО LACOBEL® ЧЁРНОЕ',
                num: 10738,
              },
              { carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО', num: 9081 },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО ТОНИРОВАННОЕ СЕРОЕ',
                num: 10146,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    moduleWidth: 550,
    option: [
      {
        optionDoor: 'DP',
        optionPrice: [
          {
            doorHandle: 'Р1 (1050мм)',
            price: [
              { carcassName: 'ЛДСП', num: 2930 },
              { carcassName: 'ЛДСП + ШПОН (масло)', num: 3897 },
              { carcassName: 'ЛДСП + ШПОН (лак)', num: 5801 },
            ],
          },
          {
            doorHandle: 'Р2 (200мм)',
            price: [
              { carcassName: 'ЛДСП', num: 3430 },
              { carcassName: 'ЛДСП + ШПОН (масло)', num: 4397 },
              { carcassName: 'ЛДСП + ШПОН (лак)', num: 6301 },
            ],
          },
        ],
      },
      {
        optionDoor: 'DR',
        optionPrice: [
          {
            doorHandle: 'Р1 (1050мм)',
            price: [
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО ТОНИРОВАННОЕ ЧЁРНОЕ',
                num: 10683,
              },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО LACOBEL® ЧЁРНОЕ',
                num: 10760,
              },
              { carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО', num: 9016 },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО ТОНИРОВАННОЕ СЕРОЕ',
                num: 10136,
              },
            ],
          },
          {
            doorHandle: 'Р2 (200мм)',
            price: [
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО ТОНИРОВАННОЕ ЧЁРНОЕ',
                num: 11183,
              },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО LACOBEL® ЧЁРНОЕ',
                num: 11260,
              },
              { carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО', num: 9516 },
              {
                carcassName: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО ТОНИРОВАННОЕ СЕРОЕ',
                num: 10636,
              },
            ],
          },
        ],
      },
    ],
  },
]
const frameDoorsList = [
  {
    value: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО ТОНИРОВАННОЕ ЧЁРНОЕ',
    id: 0,
  },
  {
    value: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + СТЕКЛО LACOBEL® ЧЁРНОЕ',
    id: 1,
  },
  {
    value: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО',
    id: 2,
  },
  {
    value: 'ПРОФИЛЬ Alu ЧЁРНЫЙ + ЗЕРКАЛО ТОНИРОВАННОЕ СЕРОЕ',
    id: 3,
  },
]
const frameDoorsHandleTypeList = [
  { value: 'Р1 (1050мм)', id: 0 },
  { value: 'Р2 (200мм)', id: 1 },
]
const doorTypes = [
  { name: 'DPL', img: '/images/DPL.png' },
  { name: 'DPR', img: '/images/DPR.png' },
  { name: 'DRL', img: '/images/DRL.png' },
  { name: 'DRR', img: '/images/DRR.png' },
]
