import React from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import { Text } from '@ui'

export const StepTitle = ({ title, titleStep }) => {
  return (
    <StepTitleBox>
      <Text light size="18px">
        {title}
      </Text>
      <Text size="24px">{titleStep}</Text>
    </StepTitleBox>
  )
}

StepTitle.propTypes = {
  title: PropTypes.string,
  titleStep: PropTypes.string,
}

StepTitle.defaultProps = {
  title: undefined,
  titleStep: undefined,
}

const StepTitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  ${Text} {
    margin-right: 5px;
  }
`
