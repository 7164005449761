import { useEffect, useMemo } from 'react'
import { findIndex, propEq } from 'ramda'
import { useStore } from 'effector-react'

import { protectedRoute } from '@features/common'
import {
  initiateTotalPriceModel,
  initiateSuccessStepModel,
  initiateOrderPrintModel,
} from '@features/order'

import { initiateCarcassModel } from '@features/carcass'
import { initiateCheckoutModel } from '@features/checkout'
import { initiateDoorModel } from '@features/door'
import { initiateFillingModel } from '@features/filling'

import { history } from '@lib/routing'
import { useLocation } from '@lib/router-hooks'
import { readScope } from '@lib/effector-helpers'

const stepList = [
  { link: '/step1', name: 'Корпус' },
  { link: '/step2', name: 'Наполнение' },
  { link: '/step3', name: 'Двери' },
  { link: '/step4', name: 'Оформление' },
  { link: '/step5', name: 'Заказ' },
]

const stepsContent = stepList.map(value => value.name)

export const initiatePageModel = () => {
  protectedRoute()
  const { loadHeightData, $heightCurrent, loadCarcassData } = readScope(
    'carcass',
    initiateCarcassModel,
  )
  const { loadFillingData } = readScope('filling', initiateFillingModel)
  const { loadDoorData } = readScope('door', initiateDoorModel)
  readScope('checkout', initiateCheckoutModel)
  readScope('total-price', initiateTotalPriceModel)
  readScope('order-print', initiateOrderPrintModel)

  const heightCurrent = useStore($heightCurrent)

  const { redirectToCurrentStep } = readScope(
    'success-step',
    initiateSuccessStepModel,
  )

  useEffect(() => {
    loadHeightData()

    if (heightCurrent) {
      console.log('loadMount Data', heightCurrent)
      loadCarcassData({ heightCurrent })
      loadFillingData({ heightCurrent })
      loadDoorData({ heightCurrent })
    }
  }, [heightCurrent, loadCarcassData, loadFillingData, loadHeightData])

  const location = useLocation()

  const activeIndex = useMemo(
    () => findIndex(propEq('link', location.pathname))(stepList),
    [location.pathname],
  )

  useEffect(() => {
    if (location.pathname === '/') {
      history.replace('/step1')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  // TODO bug
  useEffect(() => {
    redirectToCurrentStep()
  }, [redirectToCurrentStep])

  return {
    activeIndex,
    stepsContent,
  }
}
