import React from 'react'
import PropTypes from 'prop-types'

import { readScope } from '@lib/effector-helpers'
import { useStore } from 'effector-react'

import { Row, Col } from '@lib/styled-components-layout'

import styled from 'styled-components'
import { Text } from '@ui'

export const OrderDescription = () => {
  const {
    $heightCurrent,
    $currentCarcass,
    $currentModuleWidth,
    $priceCarcass,
  } = readScope('carcass')
  const { $fillingLedCode, $fillingCode, $commonPrice, $ledPrice } = readScope(
    'filling',
  )
  const { $frameDoorsHandleTypeCurrent, $doorCode, $priceDoor } = readScope(
    'door',
  )

  const { $cupboardPrice, $totalPrice, $discount } = readScope('total-price')

  const height = useStore($heightCurrent)

  const { moduleWidth = '', commonWidth = '' } =
    useStore($currentModuleWidth) || {}
  const { name = '' } = useStore($currentCarcass) || {}

  const fillingLedCode = useStore($fillingLedCode)
  const fillingCode = useStore($fillingCode)

  const doorCode = useStore($doorCode)
  const { DP = '', DR = '' } = doorCode

  const frameDoorHandleType = useStore($frameDoorsHandleTypeCurrent)

  const commonPrice = useStore($commonPrice)
  const ledPrice = useStore($ledPrice)
  const cupboardPrice = useStore($cupboardPrice)
  const totalPrice = useStore($totalPrice)
  const discount = useStore($discount)
  const priceCarcass = useStore($priceCarcass)
  const priceDoor = useStore($priceDoor)

  return (
    <Col padding="0 10px 40px 0">
      <BorderBox>
        <Text light narrow style={{ padding: '0 15px' }}>
          Габаритные размеры:
        </Text>
        <Row width="100%" justify="space-between" padding="0 15px">
          <Text bold>Ш &#215; В &#215; Г</Text>

          <Row>
            <Text bold>{commonWidth}</Text>
            <Text bold>&#215;</Text>
            <Text bold>{height}</Text>
            <Text bold>&#215;</Text>
            <Text bold>620</Text>
          </Row>
        </Row>
      </BorderBox>

      <BorderBottomBox>
        <Row width="100%" justify="space-between">
          <Text>Наименование</Text>
          <Text>Цена</Text>
        </Row>
      </BorderBottomBox>

      <BorderBottomBox>
        <Text light>Код корпуса</Text>
        <Row width="100%" justify="space-between">
          <Text>{`702.${moduleWidth}-${name}`}</Text>
          <Text>{priceCarcass}</Text>
        </Row>
      </BorderBottomBox>

      <BorderBottomBox>
        <Text light>Код комплекта наполнения</Text>
        <Row width="100%" justify="space-between">
          <Text>{`702.${moduleWidth}-${fillingCode}`}</Text>
          <Text>{commonPrice}</Text>
        </Row>
      </BorderBottomBox>

      <BorderBottomBox>
        <Text light>Код комплекта освещения</Text>
        <Row width="100%" justify="space-between">
          <Text>{`702.${moduleWidth}-${fillingLedCode}`}</Text>
          <Text>{ledPrice}</Text>
        </Row>
      </BorderBottomBox>

      <BorderBottomBox>
        <Text light>Код комплекта дверей</Text>
        <Row width="100%" justify="space-between">
          <Text>{`702.${moduleWidth}-DP${DP} DR${DR}-${
            DR || DP ? frameDoorHandleType : ''
          }`}</Text>
          <Text>{priceDoor}</Text>
        </Row>
      </BorderBottomBox>

      <BorderBottomBox>
        <Row width="100%" justify="space-between">
          <Text>Стоимость шкафа</Text>
          <Text>{cupboardPrice}</Text>
        </Row>
      </BorderBottomBox>

      {Boolean(discount) && (
        <DiscountBox>
          <Row width="100%" justify="space-between">
            <Text>Скидка</Text>
            <Text>{discount}</Text>
          </Row>
        </DiscountBox>
      )}

      <PriceOrderBox>
        <Row width="100%" justify="space-between">
          <Text black>Стоимость заказа</Text>
          <Text black>&#8372; {totalPrice}</Text>
        </Row>
      </PriceOrderBox>
    </Col>
  )
}

export const BorderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
  border: 1px solid #a1a1a1;
  box-sizing: border-box;
  margin-bottom: 20px;
`

const BorderBottomBox = styled(BorderBox)`
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #f5efef;
  margin-bottom: 0;
`

const DiscountBox = styled(BorderBottomBox)`
  border-bottom: 1px solid #3c3d3e;
  margin-bottom: 15px;
`
const PriceOrderBox = styled(BorderBottomBox)`
  border-bottom: none;
  margin-bottom: 0;
`

OrderDescription.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  priceCarcass: PropTypes.number,
  priceFilling: PropTypes.number,
  priceLighting: PropTypes.number,
  priceDoors: PropTypes.number,
  codeCarcass: PropTypes.string,
  codeFilling: PropTypes.string,
  codeLighting: PropTypes.string,
  codeDoors: PropTypes.string,
  priceCupboard: PropTypes.number,
  priceOrder: PropTypes.number,
  discount: PropTypes.number,
}

OrderDescription.defaultProps = {
  width: 0,
  height: 0,
  priceCarcass: 0,
  priceFilling: 0,
  priceLighting: 0,
  priceDoors: 0,
  codeCarcass: 'нет',
  codeFilling: 'нет',
  codeLighting: 'нет',
  codeDoors: 'нет',
  priceCupboard: 0,
  priceOrder: 0,
  discount: 0,
}
