import React from 'react'
import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'
import { Text } from '@ui'

export const StepLine = ({ list, activeIndex }) => {
  return (
    <StepLineBox>
      {list.map((item, index) => (
        <Item key={index}>
          <ItemBox>
            <CircleBox>
              <OutEllipseBox
                isActive={index === activeIndex}
                isCompleted={index < activeIndex}
              >
                <IntEllipseBox
                  isActive={index === activeIndex}
                  isCompleted={index < activeIndex}
                />
              </OutEllipseBox>
            </CircleBox>
            <Line isCompleted={index < activeIndex} />
          </ItemBox>

          <Text
            isActive={index === activeIndex}
            isCompleted={index < activeIndex}
          >
            {item}
          </Text>
        </Item>
      ))}
    </StepLineBox>
  )
}

StepLine.propTypes = {
  list: PropTypes.array,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
  activeIndex: PropTypes.number,
}

StepLine.defaultProps = {
  // list: ['Корпус', 'Наполнение', 'Двери', 'Оформление'],
  isActive: false,
  isCompleted: false,
  // activeIndex: 0,
}

const Item = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const ItemBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #a1a1a1;

  ${p =>
    p.isCompleted &&
    css`
      background-color: #343434;
    `};
  ${Item}:last-child & {
    display: none;
  }
`

const CircleBox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  background-color: #e5e5e5;
`

const OutEllipseBox = styled(CircleBox)`
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 2px solid #a1a1a1;
  border-radius: 50%;
  ${p =>
    p.isActive &&
    css`
      border: 2px solid #343434;
      border-radius: 50%;
    `};
  ${p =>
    p.isCompleted &&
    css`
      background-image: url(/images/check.svg);
      border: none;
    `}
`

const IntEllipseBox = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #a1a1a1;
  ${p =>
    p.isActive &&
    css`
      background-color: #343434;
    `};
  ${p =>
    p.isCompleted &&
    css`
      background-color: transparent;
    `}
`

const StepLineBox = styled.ul`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
